import React, { Component, useState } from 'react';
import HttpService from '../http/http-service';
import Header from "../general/header";
import Footer from "../general/footer";
import { AgGridReact } from 'ag-grid-react';
import { Modal } from 'react-bootstrap';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import ImgEdit from '../assets/images/icon_edit.png';
import ImgRemove from '../assets/images/delete.png';
import Autosuggest from 'react-autosuggest';
import SwAlert from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const AlertSW = withReactContent(SwAlert)
const Toast = SwAlert.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
})

var institutes = []
var selectedInstitute = null;
var loggedInUserRoleType = localStorage.getItem("loggedInUserRoleType") ? Number(localStorage.getItem("loggedInUserRoleType")) : -1;
var loggedInUserId = localStorage.getItem("loggedInUserId") ? localStorage.getItem("loggedInUserId") : '00000000-0000-0000-0000-000000000000';
var courseCode = []

function renderSuggestion(suggestion) {
    return (
        <div>
            <span>{suggestion.OrgName}</span>
            <span className="city-name">{suggestion.City}</span>
        </div>
    );
}

function renderCourseCodeSuggestion(suggestion) {
    return (
        <div>
            <span>{suggestion.TRANSFER_EVENT_ID}</span>
            {/* <span className="city-name">{suggestion.City}</span> */}
        </div>
    );
}

function shouldRenderSuggestions() {
    return true;
}

function getSuggestionValue(suggestion) {
    selectedInstitute = suggestion;
    return suggestion.OrgName;
}

function getCourseCodeSuggestionValue(suggestion) {
    return suggestion.TRANSFER_EVENT_ID;
}

function getSuggestions(value) {
    return institutes.filter(institute => String(institute.OrgName).toLowerCase().includes(value.toLowerCase()));
}

function getCourseCodeSuggestions(value) {
    return courseCode.filter(courseCode => String(courseCode.TRANSFER_EVENT_ID).toLowerCase().includes(value.toLowerCase()));
}

function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}

class CoursePortfolio extends Component {


    ApplicantID = null;

    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [
                { field: "CourseCode", headerName: "COURSE CODE", sortable: true, width: 200, sort: 'asc' },
                { field: "CourseTitle", headerName: "TITLE", sortable: true, width: 400 },
                { field: "Grade", headerName: "GRADE", sortable: true, width: 180 },
                { field: "Credit", headerName: "CREDITS", sortable: true, width: 200, aggFunc: "sum" },
                { field: "actions", headerName: "ACTIONS", cellRenderer: "CPButtonRenderer", width: 140 }
            ],
            rowData: [],
            frameworkComponents: {
                CPButtonRenderer: CPButtonRenderer,
            },
            context: { componentParent: this },
            applicantInstituteData: [],
            gridOptions: {
                defaultColDef: {
                    editable: false
                }
            },
            paginationPageSize: 5,
            showDlAddInstitute: false,
            showDlAddCourse: false,
            courseDlBtnSave: 'Add',
            applicant: [],
            value: '',
            suggestions: [],
            courseCodeSuggestions: [],
            newCourse: {
                Id: { value: '' },
                ApplicantOrganizationId: { value: '' },
                CourseCode: { value: '' },
                CourseTitle: { value: '' },
                Grade: { value: '' },
                Credit: { value: '' },
                Suffix: { value: 'none' }
            },
            elements: [],
            errors: {
                value: '',
                ApplicantOrganizationId: '',
                CourseCode: '',
                CourseTitle: '',
                Grade: '',
                Credit: '',
                Suffix: ''
            },
            lastSelectedInstituteId: ''
        };
        loggedInUserRoleType = localStorage.getItem("loggedInUserRoleType") ? Number(localStorage.getItem("loggedInUserRoleType")) : -1;
    }

    search = () => {
        //    this.state.gridOptions.api.setQuickFilter(document.getElementById('filter-text-box').value);
    }

    onBack = () => {
        this.props.history.push('/dashboard')
    }

    handleShowInstitute = () => { this.setState({ showDlAddInstitute: true }); };
    handleCloseInstitute = () => { this.setState({ showDlAddInstitute: false }); this.resetAttributes(); };
    handleShowCourse = () => {
        this.setState({ showDlAddCourse: true });
    };
    showCourseDlg = () => {
        this.handleShowCourse();
        this.setState({ courseDlBtnSave: 'Save' });
        if (this.state.applicantInstituteData.length > 0) {
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.newCourse.ApplicantOrganizationId.value = this.state.lastSelectedInstituteId ? this.state.lastSelectedInstituteId : this.state.applicantInstituteData[0].Id;
            this.getCourseCodeList(this.state.newCourse.ApplicantOrganizationId.value);
        }
    }
    handleCloseCourse = () => {
        this.setState({ showDlAddCourse: false });
        this.resetAttributes();
    };

    onAddInstitution = (event) => {
        event.preventDefault();
        this.addInstitution();
    }

    onSaveCourse = (event) => {
        event.preventDefault();
        this.addCourse();
    }

    removeCoursePortfolio = (row) => {
        AlertSW.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#C30E2E',
            cancelButtonColor: '#C30E2E',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                this.removeCoursePortfolioCall(row);
            }
        })
    }

    removeInstitute = (row) => {
        AlertSW.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#C30E2E',
            cancelButtonColor: '#C30E2E',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                this.removeInstituteCall(row);
            }
        })
    }

    editCoursePortfolio = (row) => {
        this.editCoursePortfolioCall(row);
    }

    changeHandler = event => {
        const re = /^[0-9]+$/;

        const name = event.target.name;
        const value = event.target.value;

        if (name === 'Credit' && !(event.target.value === '' || re.test(event.target.value))) {
            return;
        }

        this.setState({
            newCourse: {
                ...this.state.newCourse,
                [name]: {
                    ...this.state.newCourse[name],
                    value
                }
            }
        });
        this.validationHandler(name, value);
    }

    validationHandler(name, value) {
        let errors = this.state.errors;

        switch (name) {
            case 'ApplicantOrganizationId':
                errors.ApplicantOrganizationId = value ? '' : 'Select Institution.';
                break;
            case 'CourseCode':
                errors.CourseCode = value && value.length <= 100 ? '' : 'CourseCode required.';
                break;
            // case 'CourseTitle':
            //     errors.CourseTitle = value && value.length <= 100 ? '' : 'CourseTitle required.';
            //     break;
            case 'Grade':
                errors.Grade = value ? '' : 'Select Grade.';
                break;
            case 'Suffix':
                errors.Suffix = value ? '' : 'Select Suffix.';
                break;
            case 'Credit':
                if (!value && value != 0) {
                    errors.Credit = 'Credits must be populated!';
                } else if (!Number(value) && value != 0) {
                    errors.Credit = 'Credits must be number!';
                } else if (Number(value) && Number(value).toString().length > 3) {
                    errors.Credit = 'Credits must be 3 digit only.';
                } else {
                    errors.Credit = '';
                }
                //errors.Credit = value && String(value).length <= 2 ? '' : 'Credits must be populated!';
                break;
            default:
                break;
        }
        this.setState({ errors, [name]: value });
    }

    async addInstitution() {
        if (selectedInstitute) {
            var objInstitute = institutes.find(item => item.OrgName === this.state.value)
            let data = {
                ApplicantId: this.state.applicant.Id,
                OrganizationId: objInstitute.OrganizationId,
                OrganizationName: objInstitute.OrgName,
                OrganizationCodeId: objInstitute.OrgCodeId,
                OrganizationIdentifier: objInstitute.OrganizationIdentifier,
                CreatedBy: loggedInUserId
            }
            const result = await HttpService.post('ApplicantOrganizations/PostApplicantOrganization', data, "")
            if (result && result.ReturnStatus === true) {
                Toast.fire({ title: result.Message, type: 'success' });
                this.getApplicantOrganization();
                this.handleCloseInstitute();
            } else {
                Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
            }
        } else {
            this.setState({ errors: { value: "Institution required." } })
        }

    }

    async addCourse() {
        this.validationHandler("ApplicantOrganizationId", this.state.newCourse.ApplicantOrganizationId.value);
        this.validationHandler("Grade", this.state.newCourse.Grade.value);
        this.validationHandler("Suffix", this.state.newCourse.Suffix.value);
        this.validationHandler("CourseCode", this.state.newCourse.CourseCode.value);
        // this.validationHandler("CourseTitle", this.state.newCourse.CourseTitle.value);
        this.validationHandler("Credit", this.state.newCourse.Credit.value);

        if (validateForm(this.state.errors)) {
            let data = {
                ApplicantOrganizationId: this.state.newCourse.ApplicantOrganizationId.value,
                CourseCode: this.state.newCourse.CourseCode.value,
                CourseTitle: this.state.newCourse.CourseTitle.value ? this.state.newCourse.CourseTitle.value : '',
                Grade: this.state.newCourse.Grade.value,
                Credit: this.state.newCourse.Credit.value,
                Id: this.state.newCourse.Id.value,
                Suffix: this.state.newCourse.Suffix.value,
                CreatedBy: loggedInUserId,
                ApplicantId: this.ApplicantID
            }
            const result = await HttpService.post('CoursePortfolios/PostCoursePortfolio', data, "")
            if (result && result.ReturnStatus === true) {
                Toast.fire({ title: result.Message, type: 'success' });
                this.getCoursePortfolioList();
                this.handleCloseCourse();
            } else {
                Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
            }
        }
    }

    async removeCoursePortfolioCall(row) {
        let data = "id=" + row.data.Id;
        const result = await HttpService.get('CoursePortfolios/DeleteCoursePortfolio?' + data);
        if (result && result.ReturnStatus === true) {
            Toast.fire({ title: result.Message, type: 'success' });
            this.getCoursePortfolioList();
        } else {
            Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
        }
    }

    async editCoursePortfolioCall(row) {
        this.state.newCourse.ApplicantOrganizationId.value = row.data.ApplicantOrganizationId;
        this.state.newCourse.CourseCode.value = row.data.CourseCode;
        this.state.newCourse.CourseTitle.value = row.data.CourseTitle;
        this.state.newCourse.Credit.value = row.data.Credit === "" ? "none" : row.data.Credit;
        this.state.newCourse.Grade.value = String(row.data.Grade).substring(0, 1);
        this.state.newCourse.Id.value = row.data.Id;
        this.state.newCourse.Suffix.value = row.data.Suffix;
        this.setState({
            courseDlBtnSave: 'Update',
            value: row.data.CourseCode
        });
        this.getCourseCodeList(row.data.ApplicantOrganizationId);
        this.handleShowCourse();
    }

    async removeInstituteCall(row) {

        let data = "id=" + row;
        const result = await HttpService.get('ApplicantOrganizations/DeleteApplicantOrganization?' + data);
        if (result && result.ReturnStatus === true) {
            Toast.fire({ title: result.Message, type: 'success' });
            this.getApplicantOrganization();
        } else {
            Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
        }
    }


    componentDidMount() {
        this.ApplicantID = this.props.match.params.ApplicantID;
        this.checkSession();
        this.getCoursePortfolioList();
        this.getApplicant(this.ApplicantID);
        this.getOrganizationList();
        this.getApplicantOrganization();
        //this.getCourseCodeList();
    }

    checkSession() {
        var object = JSON.parse(localStorage.getItem("session"));
        if (object) {
            var dateString = new Date(object.timestamp);
            var now = new Date();

            console.log('dateString ' + dateString);
            console.log('now ' + now);
            if (now > dateString) {
                this.props.history.push('/');
                Toast.fire({ title: 'Session expired!', type: 'info' });
            }
        }
    }

    async getCoursePortfolioList() {
        let data = "id=" + this.ApplicantID;
        const result = await HttpService.get('CoursePortfolios/GetCoursePortfolio?' + data);
        if (result && result.ReturnStatus === true) {
            this.setState({ rowData: result.Data });
            setTimeout(() => {
                this.createCoursePortfolio();
            }, 1000);
        } else {
            Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
        }
    }

    async getApplicant(ApplicantID) {
        let data = "id=" + ApplicantID;
        const result = await HttpService.get('Applicants/GetApplicantsPeopleById?' + data);
        if (result && result.ReturnStatus === true) {
            this.setState({ applicant: result.Data });
        } else {
            Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
        }
    }

    async getOrganizationList() {
        const result = await HttpService.get('ApplicantOrganizations/GetOrganizationList');
        if (result && result.ReturnStatus === true) {
            institutes = result.Data;
        } else {
            Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
        }
    }

    async getCourseCodeList(instituteId) {
        let data = "instituteId=" + instituteId;
        const result = await HttpService.get('CoursePortfolios/GetCourseCodeForTEC?' + data);
        if (result && result.ReturnStatus === true) {
            courseCode = result.Data;
        } else {
            Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
        }
    }

    async getApplicantOrganization() {
        let data = "id=" + this.ApplicantID;
        const result = await HttpService.get('ApplicantOrganizations/GetApplicantOrganization?' + data);
        if (result && result.ReturnStatus === true) {
            this.setState({ applicantInstituteData: result.Data });
            setTimeout(() => {
                this.createCoursePortfolio();
            }, 1000);
        } else {
            Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
        }
    }

    createCoursePortfolio() {
        let elementsTemp = [];
        for (var i = 0; i < this.state.applicantInstituteData.length; i++) {
            let rows = this.state.rowData.filter(x => x.ApplicantOrganizationId === this.state.applicantInstituteData[i]["Id"]);
            let creditTotal = 0;
            rows.forEach(element => {
                element.Grade = String(element.Grade).substring(0, 1) + (element.Suffix === "none" ? "" : element.Suffix);
                creditTotal += element.Credit;
            });
            let gridHeight = rows.length ? (rows.length * 50) + 130 : 180;
            let totalRows = [{ CourseCode: 'Total', CourseTitle: '', Grade: '', Credit: creditTotal }]
            // push the component to elements!
            elementsTemp.push(
                <div className="corse-table" key={i}>
                    <CoursePortfolioGrid
                        columnDefs={this.state.columnDefs}
                        rowData={rows}
                        totalData={totalRows}
                        instituteName={this.state.applicantInstituteData[i]["OrganizationName"] + "(" + this.state.applicantInstituteData[i]["OrganizationCodeId"] + ")"}
                        instituteId={this.state.applicantInstituteData[i]["Id"]}
                        frameworkComponents={this.state.frameworkComponents}
                        context={this.state.context}
                        gridHeight={gridHeight}
                    />
                </div>
            );
        }

        if (elementsTemp.length == 0) {
            elementsTemp.push(
                <div key="no-record" className="no-record">No Records</div>
            );
        }

        this.setState({ elements: elementsTemp })
    }

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    onCourseCodeSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            courseCodeSuggestions: getCourseCodeSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onCourseCodeSuggestionsClearRequested = () => {
        this.setState({
            courseCodeSuggestions: []
        });
    };

    onChange = (event, { newValue, method }) => {
        this.setState({
            value: newValue,
            errors: { value: "" }
        });
    };

    onCourseCodeChange = (event, { newValue, method }) => {
        this.setState({
            value: newValue,
            errors: { value: "" }
        });

        var objCourseCode = courseCode.find(item => item.TRANSFER_EVENT_ID === newValue)
        if (objCourseCode) {
            this.setState({
                newCourse: {
                    ...this.state.newCourse,
                    CourseCode: {
                        ...this.state.newCourse.CourseCode,
                        value: newValue
                    },
                    Credit: {
                        ...this.state.newCourse.Credit,
                        value: objCourseCode.MINIMUM_CREDIT
                    }
                },
                errors: { value: "" }
            });
        } else {
            this.setState({
                newCourse: {
                    ...this.state.newCourse,
                    CourseCode: {
                        ...this.state.newCourse.CourseCode,
                        value: newValue
                    },
                },
                errors: { value: "" }
            });
        }
    };

    createInstitutesItems() {
        var items = [];
        if (this.state.applicantInstituteData.length > 0) {
            for (var i = 0; i < this.state.applicantInstituteData.length; i++) {
                var obj = this.state.applicantInstituteData[i];
                if (i === 0) {
                    items.push(<option key={i} value={obj.Id}>{obj.OrganizationName}</option>);
                } else {
                    items.push(<option key={i} value={obj.Id}>{obj.OrganizationName}</option>);
                }
            }
        }
        return items;
    }

    onDropdownSelected = (event) => {
        this.changeHandler(event);
        if (event.target.name === 'ApplicantOrganizationId') {
            this.setState({ lastSelectedInstituteId: event.target.value });
            this.getCourseCodeList(event.target.value);
        }
    }

    resetAttributes() {
        selectedInstitute = null;
        this.setState({
            newCourse: {
                Id: { value: '' },
                ApplicantOrganizationId: { value: '' },
                CourseCode: { value: '' },
                CourseTitle: { value: '' },
                Grade: { value: '' },
                Credit: { value: '' },
                Suffix: { value: 'none' }
            },
            errors: {
                value: '',
                ApplicantOrganizationId: '',
                CourseCode: '',
                CourseTitle: '',
                Grade: '',
                Credit: '',
                Suffix: ''
            },
        });
        this.setState({ suggestions: [] });
        this.setState({ value: '' });
        this.setState({ courseCodeSuggestions: [] });
    }

    render() {

        const { value, suggestions, errors, courseCodeSuggestions } = this.state;
        const inputProps = {
            placeholder: "Type institute name",
            value,
            onChange: this.onChange,
            autoFocus: true
        };

        const courseCodeInputProps = {
            placeholder: "Type course code",
            value,
            onChange: this.onCourseCodeChange,
            autoFocus: false
        };
        return (
            <div>
                <Header />
                <div className="sub-header-div">
                    <div className="container">
                        <h5 className="back-btn-header" type="button" onClick={this.onBack}>Back</h5>
                        <h3 className="comman-title"> transfer course portfolio</h3>
                    </div>
                </div>
                <div className="container">
                    <div className="form-group label-header">
                        <label> {this.state.applicant.FirstName + " " + this.state.applicant.LastName} </label>
                    </div>
                    <div className="header-action-btn">
                        <button type="submit" className="btn btn-primary comman-site-btn add-course" onClick={this.showCourseDlg}
                            disabled={this.state.applicantInstituteData && this.state.applicantInstituteData.length === 0}>
                            <span className="glyphicon glyphicon-plus" aria-hidden="true"></span> Add Course</button>
                        <button type="submit" className="btn btn-primary comman-site-btn add-institution" onClick={this.handleShowInstitute} >
                            <span className="glyphicon glyphicon-plus" aria-hidden="true"></span>  Add Institution
                    </button>
                    </div>
                </div>
                <div className="full-width full-width-comman">
                    <div className="container">
                        {this.state.elements}
                    </div>
                </div>
                <Footer />
                {/* Add Institutions */}
                <Modal className="modal fade comman-modal" id="add-new-applicant" show={this.state.showDlAddInstitute} onHide={this.handleCloseInstitute}>
                    <Modal.Header className="modal-header" closeButton>
                        <Modal.Title>Add New Institution</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <form onSubmit={e => { e.preventDefault(); }}>
                            <div className="form-group">
                                <label>Search by Name / ID</label>
                                <Autosuggest
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                    getSuggestionValue={getSuggestionValue}
                                    shouldRenderSuggestions={shouldRenderSuggestions}
                                    renderSuggestion={renderSuggestion}
                                    inputProps={inputProps} />
                                <span className='error'>{errors.value}</span>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="btn-group">
                            <button type="button" variant="secondary" className="btn btn-primary comman-site-btn" onClick={this.handleCloseInstitute}>Cancel</button>
                            <button type="button" variant="primary" className="btn btn-primary comman-site-btn" onClick={this.onAddInstitution}>ADD</button>
                        </div>
                    </Modal.Footer>
                </Modal>

                {/* Add Course */}
                <Modal className="modal fade comman-modal" id="add-course" show={this.state.showDlAddCourse} onHide={this.handleCloseCourse}>
                    <Modal.Header className="modal-header" closeButton>
                        <Modal.Title>Add Course</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <form onSubmit={e => { e.preventDefault(); }}>
                            <div className="form-group">
                                <label>Institution</label>
                                <select className="normal-select" name="ApplicantOrganizationId" value={this.state.newCourse.ApplicantOrganizationId.value} onChange={this.onDropdownSelected} noValidate autoFocus>
                                    {/* <option value="" disabled>Select institute</option> */}
                                    {this.createInstitutesItems()}
                                </select>
                                <span className='error'>{errors.ApplicantOrganizationId}</span>
                            </div>
                            <div className="form-group">
                                <label>Course Code</label>
                                <Autosuggest
                                    suggestions={courseCodeSuggestions}
                                    onSuggestionsFetchRequested={this.onCourseCodeSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={this.onCourseCodeSuggestionsClearRequested}
                                    getSuggestionValue={getCourseCodeSuggestionValue}
                                    shouldRenderSuggestions={shouldRenderSuggestions}
                                    renderSuggestion={renderCourseCodeSuggestion}
                                    inputProps={courseCodeInputProps} />
                                {/* <input type="text" className="form-control amount" placeholder="Enter Course code" name="CourseCode" value={this.state.newCourse.CourseCode.value} onChange={this.changeHandler} /> */}
                                <span className='error'>{errors.CourseCode}</span>
                            </div>
                            <div className="form-group">
                                <label>Title</label>
                                <input type="text" className="form-control amount" placeholder="Enter title" name="CourseTitle" value={this.state.newCourse.CourseTitle.value} onChange={this.changeHandler} />
                                <span className='error'>{errors.CourseTitle}</span>
                            </div>
                            <div className="form-group grade">
                                <label>Grade Letter</label>
                                <select className="normal-select" name="Grade" value={this.state.newCourse.Grade.value} onChange={this.onDropdownSelected}>
                                    <option value="" disabled>Select grade</option>
                                    <option value="A">A</option>
                                    <option value="B">B</option>
                                    <option value="C">C</option>
                                    <option value="D">D</option>
                                    <option value="E">E</option>
                                    <option value="F">F</option>
                                </select>
                                <span className='error'>{errors.Grade}</span>
                            </div>
                            <div className="form-group credit">
                                <label>suffix</label>
                                <select className="normal-select" name="Suffix" value={this.state.newCourse.Suffix.value} onChange={this.onDropdownSelected}>
                                    {/* <option value="" disabled>Select Suffix</option> */}
                                    <option value="none">none</option>
                                    <option value="+">+</option>
                                    <option value="-">-</option>
                                </select>
                                <span className='error'>{errors.Suffix}</span>
                            </div>
                            <div className="form-group credit-main">
                                <label>Credits</label>
                                <input type="text" className="form-control amount" maxLength="3" placeholder="Enter credits" name="Credit" value={this.state.newCourse.Credit.value} onChange={this.changeHandler} />
                                <span className='error'>{errors.Credit}</span>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="btn-group">
                            <button type="button" variant="secondary" className="btn btn-primary comman-site-btn" onClick={this.handleCloseCourse}>Cancel</button>
                            <button type="button" variant="primary" className="btn btn-primary comman-site-btn" onClick={this.onSaveCourse}>{this.state.courseDlBtnSave}</button>
                        </div>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    };
}

export default CoursePortfolio

class CoursePortfolioGrid extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.removeInstitute = this.removeInstitute.bind(this);
    }

    handleShowCourse = () => { this.props.handleShowCourse(); };

    removeInstitute() {
        this.props.context.componentParent.removeInstitute(this.props.instituteId);
    }
    render() {
        return (
            <div>
                <div className="ag-theme-material"
                    style={{
                        height: this.props.gridHeight + 'px',
                        width: '100%'
                    }}
                >
                    <div className="table-header">
                        <label>{this.props.instituteName}</label>
                        <div className="header-action">
                            <button type="button" className="btn btn-primary comman-site-btn delete-btn" onClick={this.removeInstitute} ><img src={ImgRemove} alt="ImgRemove" /></button>
                        </div>
                    </div>
                    <div style={{ height: (this.props.rowData.length > 0 ? (this.props.rowData.length + 1) * 46 : (2.5 * 46)), width: '100%' }}>
                        <AgGridReact
                            headerHeight="46"
                            columnDefs={this.props.columnDefs}
                            rowData={this.props.rowData}
                            suppressRowClickSelection={true}
                            suppressCellSelection={true}
                            frameworkComponents={this.props.frameworkComponents}
                            context={this.props.context}
                            enableCellTextSelection={true}
                            rowHeight="46"
                        >
                        </AgGridReact>
                    </div>
                    <div style={this.props.rowData.length > 0 ? { height: '47px', width: '100%', display: '' } : { display: 'none' }} >
                        <AgGridReact
                            columnDefs={this.props.columnDefs}
                            rowData={this.props.totalData}
                            suppressRowClickSelection={true}
                            suppressCellSelection={true}
                            context={this.props.context}
                            enableCellTextSelection={true}
                            headerHeight="0"
                            rowHeight="46"
                            frameworkComponents={this.props.frameworkComponents}
                            rowStyle={{ fontWeight: 'bold' }}
                        >
                        </AgGridReact>
                    </div>

                </div >
            </div >
        );
    }
}

export class CPButtonRenderer extends Component {
    constructor(props) {
        super(props);
        this.removeCoursePortfolio = this.removeCoursePortfolio.bind(this);
        this.editCoursePortfolio = this.editCoursePortfolio.bind(this);
    }

    editCoursePortfolio() {
        this.props.context.componentParent.editCoursePortfolio(this.props.node);
    }

    removeCoursePortfolio() {
        this.props.context.componentParent.removeCoursePortfolio(this.props.node);
    }

    render() {
        return (
            <div>
                <button type="button" className="btn btn-primary comman-site-btn" style={this.props.node.data.CourseCode === 'Total' ? { display: 'none' } : { display: '' }} onClick={this.editCoursePortfolio} ><img src={ImgEdit} alt="ImgEdit" /></button>
                <button type="button" className="btn btn-primary comman-site-btn" style={this.props.node.data.CourseCode === 'Total' ? { display: 'none' } : { display: '' }} onClick={this.removeCoursePortfolio}><img src={ImgRemove} alt="ImgRemove" /></button>
            </div>
        );
    }
};