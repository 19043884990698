/************************************** 
 * HTTP services (GET, POST) for server calls
 ***************************************/
import config from '../config';
import axios from 'axios';


export default class HttpService {

    static ROOT_API() {
        return config.API_URL;
    }
    // http get request
    static async get(path, requestParam = null, headers = null) {
        const date = new Date();
        var object = { value: "value", timestamp: date.setTime(date.getTime() + (30 * 60000)) }
        localStorage.setItem("session", JSON.stringify(object));
        var requestUrl = this.ROOT_API() + path;
        var returnData = null;
        document.body.classList.add('busy-cursor');
        await axios({
            method: 'GET',
            url: requestUrl,
            data: requestParam,
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.data).then((data) => {
            document.body.classList.remove('busy-cursor');
            returnData = data;
        }).catch(function (e) {
            document.body.classList.remove('busy-cursor');
            returnData = e;
        });

        return await returnData;
    }

    // http post request
    static async post(path, requestParam = null, headers = null) {
        const date = new Date();
        var object = { value: "value", timestamp: date.setTime(date.getTime() + (30 * 60000)) }
        localStorage.setItem("session", JSON.stringify(object));
        var requestUrl = this.ROOT_API() + path;
        var returnData = null;
        document.body.classList.add('busy-cursor');
        await axios({
            method: 'POST',
            url: requestUrl,
            data: requestParam,
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.data).then((data) => {
            document.body.classList.remove('busy-cursor');
            returnData = data;
        }).catch((e) => {
            document.body.classList.remove('busy-cursor');
            returnData = e;
        });

        return await returnData;
    }

    // function put(url, data = null, headers = null, includePublic = false, includeBase = true, progressData) {
    //     let finalUrl = includeBase ? ((includePublic ? publicPath : privatePath) + url) : url;
    //     var config = {
    //         onUploadProgress: function (progressEvent) {
    //             var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    //             if (progressData) progressData(percentCompleted);
    //         }
    //     };

    //     return httpClient.request({
    //         method: 'put', url: finalUrl, headers: headers, data: data, onUploadProgress: config.onUploadProgress
    //     }).then(function (res) {
    //         return Promise.resolve(res);
    //     }).catch(function (err) {
    //         return Promise.reject(err);
    //     });
    // }

}
// module.exports = {
//     get: get,
//     post: post,
//     put: put
// }