import React, { Component, useState } from 'react';
import HttpService from '../http/http-service';
import Header from "../general/header";
import Footer from "../general/footer";
import { AgGridReact } from 'ag-grid-react';
import { Modal } from 'react-bootstrap';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import ImgRemove from '../assets/images/delete.png';
import Autosuggest from 'react-autosuggest';
import SwAlert from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const AlertSW = withReactContent(SwAlert)
const Toast = SwAlert.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
})

var applicantList = []
var selectedApplicant = null;
var loggedInUserRoleType = localStorage.getItem("loggedInUserRoleType") ? Number(localStorage.getItem("loggedInUserRoleType")) : -1;
var loggedInUserId = localStorage.getItem("loggedInUserId") ? localStorage.getItem("loggedInUserId") : '00000000-0000-0000-0000-000000000000';

function renderSuggestion(suggestion) {
    return (
        <span>{suggestion.FULL_NAME}</span>
    );
}

function shouldRenderSuggestions() {
    return true;
}

function getSuggestionValue(suggestion) {
    selectedApplicant = suggestion;
    return suggestion.FULL_NAME;
}

function getSuggestions(value) {

    const escapedValue = escapeRegexCharacters(value.trim());
    const regex = new RegExp('^' + escapedValue, 'i');

    return applicantList.filter(institute => String(institute.FULL_NAME).toLowerCase().includes(value.toLowerCase()));
}

function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [
                { field: "ApplicantID", headerName: "ID", sortable: true, width: 50 },
                { field: "ApplicantName", headerName: "APPLICANT NAME", sortable: true, width: 80 },
                { field: "AcademicYearTerm", headerName: "ACADEMIC YEAR/TERM", sortable: true, width: 60 },
                {
                    field: "Status", headerName: "STATUS", sortable: true, width: 70,
                    // cellStyle: function (params) {
                    //     if (params.value == 'In Progress') {
                    //         return { color: '#EB9D00' };
                    //     } else if (params.value == 'Submitted') {
                    //         return { color: '#BC71DE' };
                    //     } else if (params.value == 'TCE Generated') {
                    //         return { color: '#019FD1' };
                    //     } else if (params.value == 'TCE Approved') {
                    //         return { color: '#00AE68' };
                    //     }
                    //     return { color: '#000000' };
                    // }
                    cellStyle: function (params) {
                        if (params.value === 'New' || params.value === 'Returned from Evaluator') {
                            return { color: '#EB9D00' };
                        } else if (params.value === 'Under Evaluation' || params.value === 'Student Approved') {
                            return { color: '#BC71DE' };
                        } else if (params.value === 'Evaluator Submitted' || params.value === 'Evaluator Review') {
                            return { color: '#00AE68' };
                        } else if (params.value === 'Returned from Approver') {
                            return { color: '#FF0000' };
                        } else if (params.value === 'Sent to Slate') {
                            return { color : '#6a9cf7'}
                        }
                        return { color: '#000000' };
                    }
                },
                { field: "Owner", headerName: "OWNER", width: 50 },                
                { field: "actions", headerName: "ACTIONS", cellRenderer: "CPButtonRenderer", width: 80 }
            ],
            rowData: [],
            gridOptions: {
                defaultColDef: {
                    editable: false
                }
            },
            frameworkComponents: {
                CPButtonRenderer: CPButtonRenderer,
            },
            context: { componentParent: this },
            paginationPageSize: 20,
            show: false,
            //applicantList: [],
            applicantYearList: [],
            applicantTermList: [],
            //termItems: [],
            newApplicant: {
                ApplicantID: { value: '' },
                FirstName: { value: '' },
                LastName: { value: '' },
                AcademicTerm: { value: '' },
                AcademicYear: { value: '' },
                LastCollege: { value: '' },
            },
            errors: {
                value: ''
            },
            value: '',
            academicYear: '',
            academicTerm: '',
            suggestions: [],
            searchString: '',
            searchLabel: 'Search'
        };
        this.onSaveApplicant = this.onSaveApplicant.bind(this);
        this.openCoursePortfolio = this.openCoursePortfolio.bind(this);
        this.removeApplicant = this.removeApplicant.bind(this);
        loggedInUserRoleType = localStorage.getItem("loggedInUserRoleType") ? Number(localStorage.getItem("loggedInUserRoleType")) : -1;
    }

    search = (event) => {
        this.getApplicantListSearch(event)
    }

    enterPressed = (event) => {
        var code = event.keyCode || event.which;
        if (code === 13) {
            this.getApplicantListSearch(event)
        }
    }

    handleShow = () => {
        this.setState({
            show: true
        });
    };

    handleClose = () => {
        this.setState({
            show: false
        });
        this.resetAttributes();
    };

    changeHandler = event => {
        const name = event.target.name;
        const value = event.target.value;
        if (name === "Search") {
            this.state.searchLabel = "Search";
            this.setState({ searchString: value });
        } else {
            this.setState({
                newApplicant: {
                    ...this.state.newApplicant,
                    [name]: {
                        ...this.state.newApplicant[name],
                        value
                    }
                }
            });
        }
    }

    openCoursePortfolio = (row) => {
        if (loggedInUserRoleType == 0 || loggedInUserRoleType == 2 || loggedInUserRoleType == 1) {
            this.props.history.push('/coursePortfolio/' + row.data.Id)
        // } else if (loggedInUserRoleType == 1) {
        //     this.props.history.push('/coursePortfolioView/' + row.data.Id)
        // }
        }else {
            this.props.history.push('/');
            Toast.fire({ title: 'Session expired!', type: 'info' });
        }
    }

    openTCE = (row) => {
        if (loggedInUserRoleType == 0) {
            this.props.history.push('/transferCredit/' + row.data.Id)
        } else if (loggedInUserRoleType == 1) {
            this.props.history.push('/transferCreditAcademics/' + row.data.Id)
        } else if (loggedInUserRoleType == 2) {
            this.props.history.push('/transferCreditRegistrar/' + row.data.Id)
        } else {
            this.props.history.push('/');
            Toast.fire({ title: 'Session expired!', type: 'info' });
        }
    }

    removeApplicant = (row) => {
        AlertSW.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#C30E2E',
            cancelButtonColor: '#C30E2E',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                this.removeApplicantCall(row);
            }
        })
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        //params.api.sizeColumnsToFit();
    };

    onFirstDataRendered = params => {
        params.api.sizeColumnsToFit();
    };

    componentDidMount() {
        this.checkSession();
        this.getApplicantList();
        this.getApplicantsPeopleList();
    }

    checkSession() {
        var object = JSON.parse(localStorage.getItem("session"));
        if (object) {
            var dateString = new Date(object.timestamp);
            var now = new Date();

            console.log('dateString ' + dateString);
            console.log('now ' + now);
            if (now > dateString) {
                this.props.history.push('/');
                Toast.fire({ title: 'Session expired!', type: 'info' });
            }
        }
    }

    async getApplicantList() {
        var result = null;
        if (loggedInUserRoleType == 0) {
            result = await HttpService.get('Applicants/GetApplicants');
        } else if (loggedInUserRoleType == 1) {
            const data = "ApplicantType=" + 1;
            result = await HttpService.get('Applicants/GetApplicants?' + data);
        } else if (loggedInUserRoleType == 2) {
            const data = "ApplicantType=" + 2;
            result = await HttpService.get('Applicants/GetApplicants?' + data);
        } else {
            this.props.history.push('/');
            Toast.fire({ title: 'Session expired!', type: 'info' });
        }

        if (result && result.ReturnStatus === true) {
            this.setState({ rowData: result.Data });
        } else {
            Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
        }
    }

    async getApplicantListSearch(event) {
        this.state.searchString = this.state.searchLabel == "Clear" ? "" : this.state.searchString;
        const data = "searchString=" + this.state.searchString;
        const result = await HttpService.get('Applicants/GetApplicants?' + data);
        if (result && result.ReturnStatus === true) {
            this.setState({
                rowData: result.Data,
                searchLabel: this.state.searchLabel == "Clear" ? "Search" : (!this.state.searchString ? "Search" : "Clear")
            });
        } else {
            Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
        }
    }

    async getApplicantsPeopleList() {
        const result = await HttpService.get('Applicants/GetApplicantsPeople');
        if (result && result.ReturnStatus === true) {
            applicantList = result.Data;
        } else {
            Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
        }
    }

    async removeApplicantCall(row) {
        let data = "id=" + row.data.Id;
        const result = await HttpService.get('Applicants/DeleteApplicant?' + data);
        if (result && result.ReturnStatus === true) {
            Toast.fire({ title: result.Message, type: 'success' });
            this.getApplicantList();
        } else {
            Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
        }
    }

    createApplicantItems() {
        var items = [];
        if (this.state.applicantList.length > 0) {
            for (var i = 0; i < this.state.applicantList.length; i++) {
                var obj = this.state.applicantList[i];
                items.push(<option key={i} value={obj.PEOPLE_CODE_ID}>{obj.FIRST_NAME + " " + obj.LAST_NAME}</option>);
            }
        }
        return items;
    }

    // async getApplicantsYearList() {
    //     const result = await HttpService.get('Applicants/GetApplicantsAcademicYear');
    //     if (result && result.ReturnStatus === true) {
    //         this.state.applicantYearList = result.Data;
    //     } else {
    //         alert("Applicants academic years not found");
    //     }
    // }

    // createApplicantYearItems() {
    //     var items = [];
    //     if (this.state.applicantYearList.length > 0) {
    //         for (var i = 0; i < this.state.applicantYearList.length; i++) {
    //             var obj = this.state.applicantYearList[i];
    //             items.push(<option key={i} value={obj}>{obj}</option>);
    //         }
    //     }
    //     return items;
    // }

    // async getApplicantsTermList(year) {
    //     //let data = { Year: year };
    //     let data = "year=" + year;
    //     const result = await HttpService.get('Applicants/GetApplicantsAcademicTerm?' + data);
    //     if (result && result.ReturnStatus === true) {
    //         this.state.applicantTermList = result.Data;
    //         this.setState({ termItems: this.createApplicantTermItems() });
    //     } else {
    //         alert("Applicants academic years not found");
    //     }
    // }

    createApplicantTermItems() {
        var items = [];
        if (this.state.applicantTermList.length > 0) {
            for (var i = 0; i < this.state.applicantTermList.length; i++) {
                var obj = this.state.applicantTermList[i];
                items.push(<option key={i} value={obj}>{obj}</option>);
            }
        }
        return items;
    }

    // onDropdownSelected = (event) => {
    //     this.changeHandler(event);
    //     if (event.target.name == "AcademicYear") {
    //         this.getApplicantsTermList(event.target.value);
    //     }
    // }

    async onSaveApplicant(e) {
        if (selectedApplicant) {
            var objApplicant = applicantList.find(item => item.FULL_NAME === this.state.value)

            e.preventDefault();
            let data = {
                ApplicantID: objApplicant.PEOPLE_CODE_ID,
                FirstName: objApplicant.FIRST_NAME,
                LastName: objApplicant.LAST_NAME,
                AcademicYear: this.state.academicYear,
                AcademicTerm: this.state.academicTerm,
                LastCollege: '',
                CreatedBy: loggedInUserId
            };

            const result = await HttpService.post('Applicants/PostApplicant', data, "")
            if (result && result.ReturnStatus === true) {
                Toast.fire({ title: result.Message, type: 'success' });
                this.getApplicantList();
                this.handleClose();
            } else {
                Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
            }
        } else {
            this.setState({ errors: { value: "Applicant required." } })
        }
    }

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onChange = (event, { newValue, method }) => {
        this.setState({
            value: newValue
        });
        var objApplicant = applicantList.find(item => item.FULL_NAME === newValue)
        if (objApplicant) {
            this.setState({
                academicYear: objApplicant.ACADEMIC_YEAR,
                academicTerm: objApplicant.ACADEMIC_TERM,
                errors: { value: "" }
            });
        }
    };

    resetAttributes() {
        selectedApplicant = null;
        this.setState({ suggestions: [] });
        this.setState({
            value: '',
            academicYear: '',
            academicTerm: '',
            newApplicant: {
                ApplicantID: { value: '' },
                FirstName: { value: '' },
                LastName: { value: '' },
                AcademicTerm: { value: '' },
                AcademicYear: { value: '' },
                LastCollege: { value: '' },
            },
            errors: {
                value: ''
            },
        });
    }

    render() {
        const { value, suggestions, errors } = this.state;
        const inputProps = {
            placeholder: "Type applicant name",
            value,
            onChange: this.onChange
        };
        return (
            <div>
                <Header />
                <div className="sub-header-div">
                    <div className="container">
                        <h3 className="comman-title"> my transfer applicants</h3>
                    </div>
                </div>
                <div className="container">
                    <div className="form-group search-button">
                        <input type="text" id="filter-text-box" name="Search" className="form-control" onChange={this.changeHandler} placeholder="Search here" value={this.state.searchString} onKeyPress={this.enterPressed} />
                        <button type="submit" className="btn btn-primary comman-site-btn"
                            onClick={this.search}>{this.state.searchLabel}</button>
                    </div>
                    <button type="submit" className="btn btn-primary comman-site-btn add-new"
                        onClick={this.handleShow} style={(loggedInUserRoleType === 1 || loggedInUserRoleType === 2) ? { display: 'none' } : { display: '' }}>
                        <span className="glyphicon glyphicon-plus" aria-hidden="true"></span>  Add Applicant
                    </button>
                </div>
                <div className="full-width full-width-comman">
                    <div className="container">
                        <div className="ag-theme-material"
                            style={{
                                height: '1250px',
                                width: '100%'
                            }}
                        >
                            <AgGridReact
                                onGridReady={this.onGridReady}
                                columnDefs={this.state.columnDefs}
                                rowData={this.state.rowData}
                                pagination={true}
                                paginationPageSize={this.state.paginationPageSize}
                                suppressRowClickSelection={false}
                                suppressCellSelection={true}
                                frameworkComponents={this.state.frameworkComponents}
                                context={this.state.context}
                                enableCellTextSelection={true}
                                onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                            >
                            </AgGridReact>
                        </div>
                    </div>
                </div>
                <Footer />

                <Modal className="modal fade comman-modal" id="add-new-applicant" show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add New Applicant</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={e => { e.preventDefault(); }}>
                            <div className="form-group">
                                <label>Search by Name / ID</label>
                                <Autosuggest
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                    getSuggestionValue={getSuggestionValue}
                                    shouldRenderSuggestions={shouldRenderSuggestions}
                                    renderSuggestion={renderSuggestion}
                                    inputProps={inputProps} />
                                <span className='error'>{errors.value}</span>
                                {/* <select className="normal-select" name="ApplicantID" onChange={this.onDropdownSelected} value={this.state.newApplicant.ApplicantID.value}>
                                    <option value="" disabled>Applicant name / ID</option>
                                    {this.createApplicantItems()}
                                </select> */}
                            </div>
                            {/* <div className="form-group">
                                <label>Last College</label>
                                <input type="text" className="form-control amount" name="LastCollege" placeholder="Enter last college" onChange={this.changeHandler} />
                            </div> */}
                            <div className="form-group">
                                <label>Academic Year</label>
                                {/* <select className="normal-select" name="AcademicYear" onChange={this.onDropdownSelected} value={this.state.newApplicant.AcademicYear.value}>
                                    <option value="" disabled>Select academic year</option>
                                    {this.createApplicantYearItems()}
                                </select> */}
                                <input type="text" disabled className="form-control amount" name="AcademicYear" placeholder="Academic Year"
                                    value={this.state.academicYear} />
                            </div>
                            <div className="form-group">
                                <label>Term</label>
                                {/* <select className="normal-select" name="AcademicTerm" onChange={this.onDropdownSelected} value={this.state.newApplicant.AcademicTerm.value}>
                                    <option value="" defaultValue disabled>Select term</option>
                                    {this.state.termItems}
                                </select> */}
                                <input type="text" disabled className="form-control amount" name="AcademicTerm" placeholder="Academic Term"
                                    value={this.state.academicTerm} />
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="btn-group">
                            <button type="button" variant="secondary" className="btn btn-primary comman-site-btn" onClick={this.handleClose}>Cancel</button>
                            <button type="button" variant="primary" className="btn btn-primary comman-site-btn" onClick={this.onSaveApplicant}>ADD</button>
                        </div>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    };
}

export default Dashboard


export class CPButtonRenderer extends Component {
    constructor(props) {
        super(props);

        this.openCoursePortfolio = this.openCoursePortfolio.bind(this);
        this.openTCE = this.openTCE.bind(this);
        this.removeApplicant = this.removeApplicant.bind(this);
    }

    openCoursePortfolio() {
        this.props.context.componentParent.openCoursePortfolio(this.props.node);
    }

    openTCE() {
        this.props.context.componentParent.openTCE(this.props.node);
    }

    removeApplicant() {
        this.props.context.componentParent.removeApplicant(this.props.node);
    }

    render() {
        return (
            <div>
                <button type="submit" className="btn btn-primary comman-site-btn Course-Portfolio"
                    onClick={this.openCoursePortfolio}
                >Course Portfolio
                </button>
                <button type="submit" className="btn btn-primary comman-site-btn"
                    onClick={this.openTCE}
                >TCE's
                </button>
                <button type="button" className="btn btn-primary comman-site-btn"
                    onClick={this.removeApplicant}
                    disabled={loggedInUserRoleType === 1 || loggedInUserRoleType === 2} >
                    <img src={ImgRemove} alt="ImgRemove" />
                </button>
            </div>
        );
    }
};