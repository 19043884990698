import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import LoginForm from './login/LoginForm';
import Dashboard from './dashboard/dashboard';
import CoursePortfolio from './course-portfolio/course-portfolio';
import TransferCredit from './transfer-credit/transfer-credit';
import TransferCreditAcademics from './transfer-credit/transfer-credit-academics';
import TransferCreditRegistrar from './transfer-credit/transfer-credit-registrar';
import TransferCreditMapping from './transfer-credit-mapping/transfer-credit-mapping';
import TransferCreditMappingView from './transfer-credit-mapping/transfer-credit-mapping-view';
import CoursePortfolioView from './course-portfolio/course-portfolio-view';

const Routes = () => (
    <Router>
        <Route exact path="/" component={LoginForm} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/coursePortfolio/:ApplicantID" component={CoursePortfolio} />
        <Route path="/coursePortfolioView/:ApplicantID" component={CoursePortfolioView} />
        <Route path="/transferCredit/:ApplicantID" component={TransferCredit} />
        <Route path="/transferCreditAcademics/:ApplicantID" component={TransferCreditAcademics} />
        <Route path="/transferCreditRegistrar/:ApplicantID" component={TransferCreditRegistrar} />
        <Route path="/transferCreditMapping/:ApplicantTCEId/:ApplicantID" component={TransferCreditMapping} />
        <Route path="/TransferCreditMappingView/:ApplicantTCEId/:ApplicantID" component={TransferCreditMappingView} />
    </Router>
);

export default Routes;