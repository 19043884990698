import React, { Component, useState } from 'react';
import HttpService from '../http/http-service';
import Header from "../general/header";
import Footer from "../general/footer";
import { AgGridReact } from 'ag-grid-react';
import { Modal } from 'react-bootstrap';
import ImgRemove from '../assets/images/delete.png';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import SwAlert from 'sweetalert2'

const Toast = SwAlert.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
})

var coursePortfolioList = [];
var loggedInUserRoleType = localStorage.getItem("loggedInUserRoleType") ? Number(localStorage.getItem("loggedInUserRoleType")) : -1;

class TransferCreditMapping extends Component {
    ApplicantID = null; ApplicantTCEId = null; intCreditNotTransferred = 0;
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [
                { field: "EventId", headerName: "COURSE CODE", sortable: true, width: 150 },
                { field: "EventName", headerName: "TITLE", sortable: true, width: 330 },
                { field: "Credits", headerName: "CREDITS", sortable: true, width: 100 },
                { field: "CoursePortfolioName", headerName: "ELIGIBLE COURSE", sortable: true, width: 500 }
            ],
            rowData: [],
            gridOptions: {
                defaultColDef: {
                    editable: false
                }
            },
            paginationPageSize: 5,
            applicant: {},
            applicantTCE: {},
            applicantTCEEventClass: [],
            elements: [],
            creditTransferred: 0,
            creditNotTransferred: 0,
            creditRemainTransferred: 0,
        };
        loggedInUserRoleType = localStorage.getItem("loggedInUserRoleType") ? Number(localStorage.getItem("loggedInUserRoleType")) : -1;
    }

    onBack = () => {
        if (loggedInUserRoleType === 0) {
            this.props.history.push('/transferCredit/' + this.ApplicantID)
        } else if (loggedInUserRoleType === 1) {
            this.props.history.push('/transferCreditAcademics/' + this.ApplicantID)
        } else if (loggedInUserRoleType === 2) {
            this.props.history.push('/transferCreditRegistrar/' + this.ApplicantID)
        } else {
            this.props.history.push('/');
            Toast.fire({ title: 'Session expired!', type: 'info' });
        }
    }

    onBackToHome = () => {
        this.props.history.push('/dashboard')
    }

    componentDidMount() {
        this.ApplicantID = this.props.match.params.ApplicantID;
        this.ApplicantTCEId = this.props.match.params.ApplicantTCEId;
        this.checkSession();
        this.getApplicant();
        this.getApplicantTCE();
        this.getCoursePortfolioForTCE();
        this.getApplicantTCEEventClass()
    }

    checkSession() {
        var object = JSON.parse(localStorage.getItem("session"));
        if (object) {
            var dateString = new Date(object.timestamp);
            var now = new Date();

            console.log('dateString ' + dateString);
            console.log('now ' + now);
            if (now > dateString) {
                this.props.history.push('/');
                Toast.fire({ title: 'Session expired!', type: 'info' });
            }
        }
    }

    async getApplicant() {
        let data = "id=" + this.ApplicantID;
        const result = await HttpService.get('Applicants/GetApplicantsPeopleById?' + data);
        if (result && result.ReturnStatus === true) {
            this.setState({ applicant: result.Data });
        } else {
            alert("Applicants not found.");
        }
    }

    async getApplicantTCE() {
        let data = "id=" + this.ApplicantID;
        const result = await HttpService.get('ApplicantTCE/GetApplicantTCEsById?' + data);
        if (result && result.ReturnStatus === true) {
            const applicantTCETemp = result.Data.find(x => x.Id == this.ApplicantTCEId);
            this.setState({ applicantTCE: applicantTCETemp });
        } else {
            alert("ApplicantTCE not found.");
        }
    }

    async getCoursePortfolioForTCE() {
        let data = "id=" + this.ApplicantID;
        const result = await HttpService.get('CoursePortfolios/GetCoursePortfolioForTCE?' + data);
        if (result && result.ReturnStatus === true) {
            coursePortfolioList = result.Data;
        } else {
            Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
        }
    }

    async getApplicantTCEEventClass() {
        let data = "id=" + this.ApplicantTCEId;
        const result = await HttpService.get('ApplicantTCEEventClasses/GetApplicantTCEEventClass?' + data);
        if (result && result.ReturnStatus === true) {
            this.setState({
                applicantTCEEventClass: result.Data,
                creditTransferred: 0,
                creditRemainTransferred: 0,
            });
            this.state.applicantTCEEventClass.forEach((element) => {
                var ApplicantTCEEvents = element.ApplicantTCEEventsVM;
                ApplicantTCEEvents.forEach((innerElement) => {
                    if (innerElement.CoursePortfolioId) {
                        this.state.creditTransferred += innerElement.Credits;
                    } else {
                        this.state.creditRemainTransferred += innerElement.Credits;
                    }
                });
            });
            setTimeout(() => {
                var cpTotalCredits = 0;
                coursePortfolioList.forEach(element => {
                    cpTotalCredits += Number(element.Credit)
                });
                this.state.creditNotTransferred = cpTotalCredits - this.state.creditTransferred;
                this.createApplicantTCEEventClass();
            }, 100);
        } else {
            alert("ApplicantTCEEventClass not found.");
        }
    }

    createApplicantTCEEventClass() {
        let elementsTemp = [];
        for (var i = 0; i < this.state.applicantTCEEventClass.length; i++) {
            let rows = this.state.applicantTCEEventClass[i]["ApplicantTCEEventsVM"];
            let gridHeight = rows.length ? (rows.length * 50) + 100 : 150;
            elementsTemp.push(
                <div className="corse-table" key={i}>
                    <ApplicantTCEEventClass
                        columnDefs={this.state.columnDefs}
                        rowData={rows}
                        instituteName={this.state.applicantTCEEventClass[i]["EventClass"]}
                        instituteId={this.state.applicantTCEEventClass[i]["Id"]}
                        gridHeight={gridHeight}
                    />
                </div>
            );
        }

        if (elementsTemp.length == 0) {
            elementsTemp.push(
                <div key="no-record" className="no-record">No Records</div>
            );
        }

        this.setState({ elements: elementsTemp })
    }

    render() {
        return (
            <div>
                <Header />
                <div className="sub-header-div">
                    <div className="container">
                        <h5 className="back-btn-header" type="button" onClick={this.onBack}>Back</h5>
                        <h3 className="comman-title"> view transfer credits evaluations </h3>
                        <h5 className="back-btn-header pull-right" type="button" onClick={this.onBackToHome}>Back to home</h5>
                    </div>
                </div>
                <div className="container">
                    <div className="applicant-header">
                        <div className="degree-left">
                            <div className="applicant-name">
                                <label>APPLICANT NAME</label>
                                <p>{this.state.applicant.FirstName + " " + this.state.applicant.LastName}</p>
                            </div>
                            <div className="applicant-name">
                                <label>DEGREE REQUIREMENT</label>
                                <p>{this.state.applicantTCE.ProgramDesc + " / " + this.state.applicantTCE.DegreeDesc + " / " + this.state.applicantTCE.CurriculumDesc + "(" + this.state.applicant.AcademicYear + " / " + this.state.applicant.AcademicTerm + ")"}</p>
                            </div>
                        </div>
                        <div className="point-right">
                            <div className="transferred">
                                <label>{this.state.creditTransferred}</label>
                                <p>CREDITS TRANSFERRED</p>
                            </div>
                            <div className="not-transferred">
                                <label>{this.state.creditNotTransferred}</label>
                                <p>CREDITS NOT TRANSFERRED</p>
                            </div>
                            <div className="credits">
                                <label>{this.state.creditRemainTransferred}</label>
                                <p>REMAINING DEGREE CREDITS</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="full-width full-width-comman">
                    <div className="container">
                        {this.state.elements}
                        {/* <div className="bottom-action-btn">
                            <button type="button" className="btn btn-primary comman-site-btn ">CANCEL</button>
                            <button type="button" className="btn btn-primary comman-site-btn ">SAVE</button>
                        </div> */}
                    </div>
                </div>
                <Footer />
            </div>
        );
    };
}

export default TransferCreditMapping

class ApplicantTCEEventClass extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    handleShowCourse = () => { this.props.handleShowCourse(); };

    render() {
        return (
            <div>
                <div className="ag-theme-material"
                    style={{
                        height: this.props.gridHeight + 'px',
                        width: '100%'
                    }}
                >
                    <div className="table-header">
                        <label>{this.props.instituteName}</label>
                    </div>
                    <AgGridReact
                        columnDefs={this.props.columnDefs}
                        rowData={this.props.rowData}
                        suppressRowClickSelection={true}
                        suppressCellSelection={true}
                        enableCellTextSelection={true}
                    >
                    </AgGridReact>
                </div >
            </div >
        );
    }
}