import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import logo from '../assets/images/logo.png';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './adalConfig';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedInUserEmail: ''
        }
    }

    onSignOut = () => {        
        setTimeout(() => {
            localStorage.clear();            
        }, 1000);
        const msalInstance = new PublicClientApplication(msalConfig);
        msalInstance.logoutRedirect('https://fs.mcny.edu/adfs/ls/?wa=wsignout1.0');
        //window.open( 'https://fs.mcny.edu/adfs/ls/?wa=wsignout1.0',"_self");        
    }

    onHeaderClick = () => {
        this.gotoHeader();
    }

    gotoHeader() {
        this.props.history.push('/dashboard');
    }

    componentDidMount() {
        this.setState({ loggedInUserEmail: localStorage.getItem('loggedInUserEmail') });
    }

    render() {
        return (
            <header>
                <div className="container">
                    <div className="header-logo">
                        <Link to="/dashboard" >
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>
                    <div className="login-user">
                        <span className="user-name">{this.state.loggedInUserEmail}</span>
                        <Link onClick={this.onSignOut} to="/">signout</Link>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;