import React, { Component, useState } from 'react';
import HttpService from '../http/http-service';
import Header from "../general/header";
import Footer from "../general/footer";
import { AgGridReact } from 'ag-grid-react';
import { Modal } from 'react-bootstrap';
import ImgRemove from '../assets/images/delete.png';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import SwAlert from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { withRouter } from 'react-router-dom';

const AlertSW = withReactContent(SwAlert)
const Toast = SwAlert.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
})

var loggedInUserRoleType = localStorage.getItem("loggedInUserRoleType") ? Number(localStorage.getItem("loggedInUserRoleType")) : -1;
var coursePortfolioList = [];
var coursePortfolioCatalogForTCE = [];
var listSelectedCourseIds = [];
var groupedOptions = [];
class TransferCreditMapping extends Component {
    ApplicantID = null; ApplicantTCEId = null; intCreditNotTransferred = 0;
    loggedInUserId = localStorage.getItem("loggedInUserId") ? localStorage.getItem("loggedInUserId") : '00000000-0000-0000-0000-000000000000';
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [
                { field: "EventId", headerName: "COURSE CODE", sortable: true, width: 150 },
                { field: "EventName", headerName: "TITLE", sortable: true, width: 330 },
                { field: "Credits", headerName: "CREDITS", sortable: true, width: 100 },
                { field: "actions", headerName: "ELIGIBLE COURSE", cellRenderer: "CPButtonRenderer", width: 500 }
            ],
            rowData: [],
            frameworkComponents: {
                CPButtonRenderer: CPButtonRenderer,
            },
            context: { componentParent: this },
            gridOptions: {
                defaultColDef: {
                    editable: false
                }
            },
            paginationPageSize: 5,
            applicant: {},
            applicantTCE: {},
            applicantTCEEventClass: [],
            elements: [],
            creditTransferred: 0,
            creditNotTransferred: 0,
            creditRemainTransferred: 0

        };
        loggedInUserRoleType = localStorage.getItem("loggedInUserRoleType") ? Number(localStorage.getItem("loggedInUserRoleType")) : -1;
    }

    onBack = () => {
        if (loggedInUserRoleType === 0) {
            this.props.history.push('/transferCredit/' + this.ApplicantID)
        } else if (loggedInUserRoleType === 1) {
            this.props.history.push('/transferCreditAcademics/' + this.ApplicantID)
        } else if (loggedInUserRoleType === 2) {
            this.props.history.push('/transferCreditRegistrar/' + this.ApplicantID)
        } else {
            this.props.history.push('/');
            Toast.fire({ title: 'Session expired!', type: 'info' });
        }
    }

    onBackToHome = () => {
        this.props.history.push('/dashboard')
    }

    onDropdownSelected = (event, row) => {
        this.onCoursePortfolioSelected(event, row)
    }

    onBackToLogin = () => {
        this.props.history.push('/')
        Toast.fire({ title: 'Session expired!', type: 'info' });
    }

    setCreditNotTransferred = (intCreditNotTransferred) => {
        this.intCreditNotTransferred = intCreditNotTransferred;
    }

    componentDidMount() {
        this.ApplicantID = this.props.match.params.ApplicantID;
        this.ApplicantTCEId = this.props.match.params.ApplicantTCEId;
        this.checkSession();
        this.getApplicant();
        this.getApplicantTCE();
        this.getCoursePortfolioForTCE();
        this.getApplicantTCEEventClass();
        this.getCoursePortfolioCatalogForTCE();
    }

    checkSession() {
        var object = JSON.parse(localStorage.getItem("session"));
        if (object) {
            var dateString = new Date(object.timestamp);
            var now = new Date();

            console.log('dateString ' + dateString);
            console.log('now ' + now);
            if (now > dateString) {
                this.props.history.push('/');
                Toast.fire({ title: 'Session expired!', type: 'info' });
            }
        }
    }

    async getApplicant() {
        let data = "id=" + this.ApplicantID;
        const result = await HttpService.get('Applicants/GetApplicantsPeopleById?' + data);
        if (result && result.ReturnStatus === true) {
            this.setState({ applicant: result.Data });
        } else {
            Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
        }
    }

    async getApplicantTCE() {
        let data = "id=" + this.ApplicantID;
        const result = await HttpService.get('ApplicantTCE/GetApplicantTCEsById?' + data);
        if (result && result.ReturnStatus === true) {
            const applicantTCETemp = result.Data.find(x => x.Id == this.ApplicantTCEId);
            this.setState({ applicantTCE: applicantTCETemp });
        } else {
            Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
        }
    }

    async getApplicantTCEEventClass() {
        let data = "id=" + this.ApplicantTCEId;
        const result = await HttpService.get('ApplicantTCEEventClasses/GetApplicantTCEEventClass?' + data);
        if (result && result.ReturnStatus === true) {
            listSelectedCourseIds = [];
            this.setState({
                applicantTCEEventClass: result.Data,
                creditTransferred: 0,
                creditRemainTransferred: 0,
            });
            this.state.applicantTCEEventClass.forEach((element) => {
                var ApplicantTCEEvents = element.ApplicantTCEEventsVM;
                ApplicantTCEEvents.forEach((innerElement) => {
                    if (innerElement.CoursePortfolioId) {
                        this.state.creditTransferred += innerElement.Credits;
                    } else {
                        this.state.creditRemainTransferred += innerElement.Credits;
                    }
                    if (innerElement.CoursePortfolioId && listSelectedCourseIds.indexOf(innerElement.CoursePortfolioId) < 0) {
                        listSelectedCourseIds.push(innerElement.CoursePortfolioId);
                    }
                });
            });
            setTimeout(() => {
                var cpTotalCredits = 0;
                coursePortfolioList.forEach(element => {
                    cpTotalCredits += Number(element.Credit)
                });
                this.state.creditNotTransferred = cpTotalCredits - this.state.creditTransferred;
                this.createApplicantTCEEventClass();
            }, 100);

        } else {
            Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
        }
    }

    async getCoursePortfolioCatalogForTCE() {
        let data = "id=" + this.ApplicantID + "&tceId=" + this.ApplicantTCEId;
        const result = await HttpService.get('CoursePortfolios/GetCoursePortfolioCatalogForTCE?' + data);
        if (result && result.ReturnStatus === true) {
            coursePortfolioCatalogForTCE = result.Data;
        } else {
            Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
        }
    }

    async getCoursePortfolioForTCE() {
        let data = "id=" + this.ApplicantID;
        const result = await HttpService.get('CoursePortfolios/GetCoursePortfolioForTCE?' + data);
        if (result && result.ReturnStatus === true) {
            coursePortfolioList = result.Data;
        } else {
            Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
        }
    }

    createApplicantTCEEventClass() {
        this.setState({ elements: [] });
        let elementsTemp = [];
        for (var i = 0; i < this.state.applicantTCEEventClass.length; i++) {
            let rows = this.state.applicantTCEEventClass[i]["ApplicantTCEEventsVM"];
            let gridHeight = rows.length ? (rows.length * 50) + 100 : 150;
            elementsTemp.push(
                <div className="corse-table" key={i}>
                    <ApplicantTCEEventClass
                        columnDefs={this.state.columnDefs}
                        rowData={rows}
                        instituteName={this.state.applicantTCEEventClass[i]["EventClass"]}
                        instituteId={this.state.applicantTCEEventClass[i]["Id"]}
                        frameworkComponents={this.state.frameworkComponents}
                        context={this.state.context}
                        gridHeight={gridHeight}
                    />
                </div>
            );
        }

        if (elementsTemp.length == 0) {
            elementsTemp.push(
                <div key="no-record" className="no-record">No Records</div>
            );
        }

        this.setState({ elements: elementsTemp })
    }

    async onCoursePortfolioSelected(event, row) {
        let data = "id=" + row.data.Id + "&coursePortfolioId=" + event.target.value;
        const result = await HttpService.get('ApplicantTCEEventClasses/UpdateCoursePortfolioInTCE?' + data);
        if (result && result.ReturnStatus === true) {
            this.getApplicantTCEEventClass();
        } else {
            //alert("CoursePortfolio not found.");
        }
    }

    onSaveTCEMapping = (e) => {
        e.preventDefault()
        this.onBack();
        // if (loggedInUserRoleType === 1) {
        //     AlertSW.fire({
        //         title: 'Are you sure',
        //         text: "Would you like to save and return TCE to Admissions?",
        //         type: 'warning',
        //         showCancelButton: true,
        //         confirmButtonColor: '#C30E2E',
        //         cancelButtonColor: '#C30E2E',
        //         confirmButtonText: 'Yes'
        //     }).then((result) => {
        //         if (result.value) {
        //             this.approveTCEAcademicCall();
        //         }
        //     })
        // } else if (loggedInUserRoleType === -1) {
        //     this.props.history.push('/');
        //     Toast.fire({ title: 'Session expired!', type: 'info' });
        // } else {
        //     Toast.fire({ title: 'TCE mapping saved successfully.', type: 'success' });
        // }
    }

    async approveTCEAcademicCall() {
        let data = "id=" + this.ApplicantTCEId + "&userId=" + this.loggedInUserId;
        const result = await HttpService.get('ApplicantTCE/ApproveTCEByAcademics?' + data);
        if (result && result.ReturnStatus === true) {
            Toast.fire({ title: result.Message, type: 'success' });
            this.onBack();
        } else {
            Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
        }
    }

    render() {
        return (
            <div>
                <Header />
                <div className="sub-header-div">
                    <div className="container">
                        <h5 className="back-btn-header" type="button" onClick={this.onBack}>Back</h5>
                        <h3 className="comman-title"> edit transfer credits evaluations </h3>
                        <h5 className="back-btn-header pull-right" type="button" onClick={this.onBackToHome}>Back to home</h5>
                    </div>
                </div>
                <div className="container">
                    <div className="applicant-header">
                        <div className="degree-left">
                            <div className="applicant-name">
                                <label>APPLICANT NAME</label>
                                <p>{this.state.applicant.FirstName + " " + this.state.applicant.LastName}</p>
                            </div>
                            <div className="applicant-name">
                                <label>DEGREE REQUIREMENT</label>
                                <p>{this.state.applicantTCE.ProgramDesc + " / " + this.state.applicantTCE.DegreeDesc + " / " + this.state.applicantTCE.CurriculumDesc + "(" + this.state.applicant.AcademicYear + " / " + this.state.applicant.AcademicTerm + ")"}</p>
                            </div>
                        </div>
                        <div className="point-right">
                            <div className="transferred">
                                <label>{this.state.creditTransferred}</label>
                                <p>CREDITS TRANSFERRED</p>
                            </div>
                            <div className="not-transferred">
                                <label>{this.state.creditNotTransferred}</label>
                                <p>CREDITS NOT TRANSFERRED</p>
                            </div>
                            <div className="credits">
                                <label>{this.state.creditRemainTransferred}</label>
                                <p>REMAINING DEGREE CREDITS</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="full-width full-width-comman">
                    <div className="container">
                        {this.state.elements}
                        <div className="bottom-action-btn">
                            {/* <button type="button" className="btn btn-primary comman-site-btn ">CANCEL</button> */}
                            <button type="button" className="btn btn-primary comman-site-btn " onClick={this.onSaveTCEMapping}>BACK</button>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    };
}

export default withRouter(TransferCreditMapping);

class ApplicantTCEEventClass extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    handleShowCourse = () => { this.props.handleShowCourse(); };

    render() {
        return (
            <div>
                <div className="ag-theme-material"
                    style={{
                        height: this.props.gridHeight + 'px',
                        width: '100%'
                    }}
                >
                    <div className="table-header">
                        <label>{this.props.instituteName}</label>
                    </div>
                    <AgGridReact
                        columnDefs={this.props.columnDefs}
                        rowData={this.props.rowData}
                        suppressRowClickSelection={true}
                        suppressCellSelection={true}
                        frameworkComponents={this.props.frameworkComponents}
                        context={this.props.context}
                        enableCellTextSelection={true}
                    >
                    </AgGridReact>
                </div >
            </div >
        );
    }
}


export class CPButtonRenderer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            urMappingData: '',
            oldSelectedId: ''
        }
        this.onDropdownSelected = this.onDropdownSelected.bind(this);
    }

    onFocus(event) {
        // if (event.target.value) {
        //     this.setState({ oldSelectedId: event.target.value });
        // }
    }

    onDropdownSelected(event) {
        // if (listSelectedCourseIds.indexOf(this.state.oldSelectedId) >= 0) {
        //     listSelectedCourseIds.splice(listSelectedCourseIds.indexOf(this.state.oldSelectedId), 1);
        // }

        // if (listSelectedCourseIds.indexOf(event.target.value) < 0) {
        //     listSelectedCourseIds.push(event.target.value);
        // }

        this.props.node.data.CoursePortfolioId = event.target.value;
        this.props.context.componentParent.onDropdownSelected(event, this.props.node);
    }

    componentDidMount() {
        this.createCoursePortfolioItems();
    }

    createCoursePortfolioItems() {
        const { history } = this.props;
        var itemsTemp = [];
        var urMappingDataTemp = ''
        var intCreditNotTransferred = 0;
        //this.state.creditNotTransferred = 0;
        if (loggedInUserRoleType == 0) {
            var tceCatalog = coursePortfolioCatalogForTCE.filter(e => e.EventId === this.props.node.data["EventId"]).map(({ TransferEventId }) => TransferEventId);
            if (tceCatalog.length > 0) {
                var cpfList = coursePortfolioList.filter(e => tceCatalog.includes(e.CourseCode));
                if (coursePortfolioList.length > 0) {
                    for (var i = 0; i < cpfList.length; i++) {
                        var obj = cpfList[i];
                        intCreditNotTransferred += obj.Credit;
                        var isItemEnable = listSelectedCourseIds.indexOf(obj.Id) >= 0 ? false : true;
                        if (isItemEnable) {
                            itemsTemp.push(<option key={i} value={obj.Id}>{obj.CourseCode + " " + obj.CourseTitle + " (" + obj.OrganizationName + ")"}</option>);
                        } else {
                            itemsTemp.push(<option key={i} value={obj.Id} disabled>{obj.CourseCode + " " + obj.CourseTitle + " (" + obj.OrganizationName + ")"}</option>);
                        }
                    }
                }
            } else if (this.props.node.data["CoursePortfolioId"]) {
                intCreditNotTransferred += this.props.node.data["CoursePortfolioName"];
                urMappingDataTemp = this.props.node.data["CoursePortfolioName"];
            }
        } else if (loggedInUserRoleType == 2 || loggedInUserRoleType == 1) {
            const TECCourseList = coursePortfolioCatalogForTCE;
            var tceCatalog = TECCourseList.filter(e => e.EventId === this.props.node.data["EventId"]).map(({ TransferEventId }) => TransferEventId);
            var cpfList = [];
            var cpfNotInList = [];
            const courseList = coursePortfolioList;
            if (tceCatalog.length > 0) {
                cpfList = courseList.filter(e => tceCatalog.includes(e.CourseCode));
                cpfNotInList = courseList.filter(e => !tceCatalog.includes(e.CourseCode));
            } else {
                cpfNotInList = courseList;
            }
            if (cpfList.length > 0) {
                for (var i = 0; i < cpfList.length; i++) {
                    var obj = cpfList[i];
                    var isItemEnable = listSelectedCourseIds.indexOf(obj.Id) >= 0 ? false : true;
                    intCreditNotTransferred += obj.Credit;
                    // itemsTemp.push(<option key={i} value={obj.Id} >{obj.CourseTitle + " (" + obj.OrganizationName + ")"}</option>);
                    if (isItemEnable) {
                        itemsTemp.push(<option id={i} value={obj.Id} optionGroup="In Transfer Policy" name={obj.CourseCode + " " + obj.CourseTitle + " (" + obj.OrganizationName + ")"} ></option>);
                    } else {
                        itemsTemp.push(<option id={i} value={obj.Id} optionGroup="In Transfer Policy" name={obj.CourseCode + " " + obj.CourseTitle + " (" + obj.OrganizationName + ")"} disabled></option>);
                    }
                }
            }
            if (cpfNotInList.length > 0) {
                for (var i = 0; i < cpfNotInList.length; i++) {
                    var obj = cpfNotInList[i];
                    var isItemEnable = listSelectedCourseIds.indexOf(obj.Id) >= 0 ? false : true;
                    intCreditNotTransferred += obj.Credit;
                    // itemsTemp.push(<option key={i} value={obj.Id} >{obj.CourseTitle + " (" + obj.OrganizationName + ")"}</option>);
                    if (isItemEnable) {
                        itemsTemp.push(<option id={i} value={obj.Id} optionGroup="Not In Transfer Policy" name={obj.CourseCode + " " + obj.CourseTitle + " (" + obj.OrganizationName + ")"} ></option>);
                    } else {
                        itemsTemp.push(<option id={i} value={obj.Id} optionGroup="Not In Transfer Policy" name={obj.CourseCode + " " + obj.CourseTitle + " (" + obj.OrganizationName + ")"} disabled></option>);
                    }
                }
            }
        } else {
            this.props.context.componentParent.onBackToLogin();
        }
        this.setState({ items: itemsTemp, urMappingData: urMappingDataTemp });
        this.props.context.componentParent.setCreditNotTransferred(intCreditNotTransferred);
        groupedOptions = itemsTemp.reduce((acc, option) => {
            const accOptionGroup = acc[option.props.optionGroup] || [];

            return {
                ...acc,
                [option.props.optionGroup]: [...accOptionGroup, option.props]
            }
        }, {});
    }

    render() {
        if (this.state.items.length > 0) {
            return (
                <div>
                    {loggedInUserRoleType !== 0 ?
                        <select className="normal-select" onChange={this.onDropdownSelected} onLoad={this.onFocus}
                            value={(this.props && this.props.node.data.CoursePortfolioId) ? this.props.node.data.CoursePortfolioId : ""}>
                            <option value="">Select Course</option>{
                                Object.keys(groupedOptions).map((optionGroupName) => (
                                    <optgroup label={optionGroupName}>
                                        {
                                            groupedOptions[optionGroupName].map(({ id, value, name }) => (
                                                <option key={id} value={value}>{name}</option>
                                            ))
                                        }
                                    </optgroup>
                                ))}
                        </select>
                        : loggedInUserRoleType === 0 &&
                        <select className="normal-select" onChange={this.onDropdownSelected} onLoad={this.onFocus}
                            value={(this.props && this.props.node.data.CoursePortfolioId) ? this.props.node.data.CoursePortfolioId : ""}>
                            <option value="">Select Course</option>
                            {this.state.items.length > 0 ? this.state.items : ""}
                        </select>
                    }
                </div>
            );
        } else if (this.state.urMappingData) {
            return (<label> {this.state.urMappingData}</label >);
        } else {
            return (<label>Not in Transfer Policy</label>);
        }
    }
};