import React, { Component, useState } from 'react';
import HttpService from '../http/http-service';
import Header from "../general/header";
import Footer from "../general/footer";
import { AgGridReact } from 'ag-grid-react';
import { Modal } from 'react-bootstrap';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import ImgEdit from '../assets/images/icon_edit.png';
import ImgViewTCE from '../assets/images/icon_view.png';
import ImgRemove from '../assets/images/delete.png';
import Autosuggest from 'react-autosuggest';
import SwAlert from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const AlertSW = withReactContent(SwAlert)
const Toast = SwAlert.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
})

var degreeReqList = [];
var selectedDegree = null;
var applicant = null;


function renderSuggestion(suggestion) {
    //2018/Fall - Undergraduate/Associate of science/Business
    return (
        <div>
            {/* <span>{suggestion.ProgramDesc + " / " + suggestion.DegreeDesc + " / " + suggestion.CurriculumDesc + "(" + applicant.AcademicYear + " / " + applicant.AcademicTerm + ")"}</span> */}
            <span>{suggestion.DegreeDescFull}</span>
        </div>
    );
}

function shouldRenderSuggestions() {
    return true;
}

function getSuggestionValue(suggestion) {
    selectedDegree = suggestion;
    //return suggestion.ProgramDesc + " / " + suggestion.DegreeDesc + " / " + suggestion.CurriculumDesc + "(" + applicant.AcademicYear + " / " + applicant.AcademicTerm + ")";
    return suggestion.DegreeDescFull;
}

function getSuggestions(value) {
    return degreeReqList.filter(degree => String(degree.DegreeDescFull).toLowerCase().includes(value.toLowerCase()));
}

class TransferCredit extends Component {
    ApplicantID = null;
    loggedInUserRoleType = localStorage.getItem("loggedInUserRoleType") ? Number(localStorage.getItem("loggedInUserRoleType")) : -1;
    loggedInUserId = localStorage.getItem("loggedInUserId") ? localStorage.getItem("loggedInUserId") : '00000000-0000-0000-0000-000000000000';
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [
                { field: "DegreeRequirement", headerName: "DEGREE REQUIREMENT", sortable: true, width: 350 },
                {
                    field: "Status", headerName: "STATUS", sortable: true, width: 200,
                    cellStyle: function (params) {
                        if (params.value === 'New' || params.value === 'Returned from Evaluator') {
                            return { color: '#EB9D00' };
                        } else if (params.value === 'Under Evaluation' || params.value === 'Student Approved') {
                            return { color: '#BC71DE' };
                        } else if (params.value === 'Evaluator Submitted' || params.value === 'Evaluator Review') {
                            return { color: '#00AE68' };
                        } else if (params.value === 'Returned from Approver') {
                            return { color: '#FF0000' };
                        } else if (params.value === 'Sent to Slate') {
                            return { color : '#6a9cf7'}
                        }
                        return { color: '#000000' };
                    }
                },
                { field: "Owner", headerName: "OWNER", width: 150 },
                { field: "actions", headerName: "ACTIONS", cellRenderer: "CPButtonRenderer", width: 450 }
            ],
            rowData: [],
            frameworkComponents: {
                CPButtonRenderer: CPButtonRenderer,
            },
            context: { componentParent: this },
            gridOptions: {
                defaultColDef: {
                    editable: false
                }
            },
            paginationPageSize: 5,
            showDlAddDegree: false,
            applicant: [],
            degreeReqList: [],
            value: '',
            suggestions: [],
            newDegree: {
                Id: { value: '' },
                ApplicantId: { value: '' },
                MatricYear: { value: '' },
                MatricTerm: { value: '' },
                Program: { value: '' },
                Degree: { value: '' },
                Curriculum: { value: '' },
                DegreeRequirementId: { value: '' },
                ProgramDesc: { value: '' },
                DegreeDesc: { value: '' },
                CurriculumDesc: { value: '' },
                ProgramKey: { value: '' },
                DegreeKey: { value: '' },
                CurriculumKey: { value: '' },
                Status: { value: '' }
            },
            errors: {
                value: ''
            },
        };
        this.loggedInUserRoleType = localStorage.getItem("loggedInUserRoleType") ? Number(localStorage.getItem("loggedInUserRoleType")) : -1;
    }

    onBack = () => {
        this.props.history.push('/dashboard')
    }

    handleShowInstitute = () => { this.setState({ showDlAddDegree: true }); };
    handleCloseInstitute = () => { this.setState({ showDlAddDegree: false }); this.resetAttributes(); };

    onAddDegree = (event) => {
        event.preventDefault();
        this.addDegreeCall();
    }

    openTCEMapping = (row) => {
        this.openTCEMappingCall(row);
    }

    openTCEMappingView = (row) => {
        this.openTCEMappingViewCall(row);
    }

    removeTCEMapping = (row) => {
        AlertSW.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#C30E2E',
            cancelButtonColor: '#C30E2E',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                this.removeTCEMappingCall(row);
            }
        })
    }

    approveTCEAdmission = (row) => {
        this.approveTCEAdmissionCall(row);
    }

    sendToAcademicOfficer = (row) => {
        this.sendToAcademicOfficerCall(row);
    }

    generateTCE = (row) => {
        this.generateTCECall(row)
    }

    componentDidMount() {
        this.ApplicantID = this.props.match.params.ApplicantID;
        this.checkSession();
        this.getApplicant();
        this.getApplicantTCEs();
    }

    checkSession() {
        var object = JSON.parse(localStorage.getItem("session"));
        if (object) {
            var dateString = new Date(object.timestamp);
            var now = new Date();

            console.log('dateString ' + dateString);
            console.log('now ' + now);
            if (now > dateString) {
                this.props.history.push('/');
                Toast.fire({ title: 'Session expired!', type: 'info' });
            }
        }
    }

    async getApplicant() {
        let data = "id=" + this.ApplicantID;
        const result = await HttpService.get('Applicants/GetApplicantsPeopleById?' + data);
        if (result && result.ReturnStatus === true) {
            this.setState({ applicant: result.Data });
            applicant = result.Data;
            this.GetApplicantDegreeReqList();
        } else {
            Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
        }
    }

    async GetApplicantDegreeReqList() {
        let data = "MatricYear=" + this.state.applicant.AcademicYear + "&MatricTerm=" + this.state.applicant.AcademicTerm;
        const result = await HttpService.get('ApplicantTCE/GetApplicantDegreeReqList?' + data);
        if (result && result.ReturnStatus === true) {
            //this.setState({ degreeReqList: result.Data });
            degreeReqList = result.Data;
            degreeReqList.forEach(element => {
                element.DegreeDescFull = element.ProgramDesc + " / " + element.DegreeDesc + " / " + element.CurriculumDesc + "(" + applicant.AcademicYear + " / " + applicant.AcademicTerm + ")";
            });
        } else {
            Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
        }
    }

    async addDegreeCall() {
        if (selectedDegree) {
            let data = {
                ApplicantId: this.ApplicantID,
                MatricYear: this.state.applicant.AcademicYear,
                MatricTerm: this.state.applicant.AcademicTerm,
                Curriculum: selectedDegree.Curriculum,
                CurriculumDesc: selectedDegree.CurriculumDesc,
                CurriculumKey: selectedDegree.CurriculumKey,
                Degree: selectedDegree.Degree,
                DegreeDesc: selectedDegree.DegreeDesc,
                DegreeId: selectedDegree.DegreeId,
                DegreeKey: selectedDegree.DegreeKey,
                DegreeRequirementId: selectedDegree.DegreeRequirementId,
                Program: selectedDegree.Program,
                ProgramDesc: selectedDegree.ProgramDesc,
                ProgramId: selectedDegree.ProgramId,
                ProgramKey: selectedDegree.ProgramKey,
                CreatedBy: this.loggedInUserId
            }
            const result = await HttpService.post('ApplicantTCE/PostApplicantTCE', data, "")
            if (result && result.ReturnStatus === true) {
                Toast.fire({ title: result.Message, type: 'success' });
                this.getApplicantTCEs();
                this.handleCloseInstitute();
            } else {
                Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
            }
        } else {
            this.setState({ errors: { value: "Degree selection required." } })
        }
    }

    async getApplicantTCEs() {
        let data = "id=" + this.ApplicantID;
        const result = await HttpService.get('ApplicantTCE/GetApplicantTCEsByAdmissionId?' + data);
        if (result && result.ReturnStatus === true) {
            this.setState({ rowData: result.Data });
        } else {
            Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
        }
    }

    openTCEMappingCall(row) {
        this.props.history.push('/transferCreditMapping/' + row.data.Id + "/" + this.ApplicantID)
    }

    openTCEMappingViewCall(row) {
        this.props.history.push('/transferCreditMappingView/' + row.data.Id + "/" + this.ApplicantID)
    }

    async removeTCEMappingCall(row) {
        let data = "id=" + row.data.Id;
        const result = await HttpService.get('ApplicantTCE/DeleteApplicantTCE?' + data);
        if (result && result.ReturnStatus === true) {
            Toast.fire({ title: result.Message, type: 'success' });
            this.getApplicantTCEs();
        } else {
            Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
        }
    }

    async approveTCEAdmissionCall(row) {
        let data = "id=" + row.data.Id + "&userId=" + this.loggedInUserId;
        const result = await HttpService.get('ApplicantTCE/ApproveTCEByAdmission?' + data);
        if (result && result.ReturnStatus === true) {
            Toast.fire({ title: result.Message, type: 'success' });
            this.getApplicantTCEs();
        } else {
            Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
        }
    }

    async sendToAcademicOfficerCall(row) {
        let data = "id=" + row.data.Id + "&userId=" + this.loggedInUserId;
        const result = await HttpService.get('ApplicantTCE/SubmitTCEToAcademicByAdmission?' + data);
        if (result && result.ReturnStatus === true) {
            Toast.fire({ title: result.Message, type: 'success' });
            this.getApplicantTCEs();
        } else {
            Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
        }
    }

    async generateTCECall(row) {
        let data = "id=" + row.data.Id + "&applicantId=" + this.ApplicantID;
        const result = await HttpService.get('ApplicantTCE/GenerateTCE?' + data);
        if (result && result.ReturnStatus === true) {
            Toast.fire({ title: result.Message, type: 'success' });
            this.getApplicantTCEs();
        } else {
            Toast.fire({ title: result.Message, type: 'error', timer: 10000 });
        }
    }

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onChange = (event, { newValue, method }) => {
        this.setState({
            value: newValue,
            errors: { value: "" }
        });
    };

    resetAttributes() {
        selectedDegree = null;
        this.setState({ suggestions: [] });
        this.setState({
            value: '',
            errors: {
                value: ''
            },
        });
    }

    render() {
        const { value, suggestions, errors } = this.state;
        const inputProps = {
            placeholder: "GRAD / MSED / Child Ed 1-6 (2018/Fall)",
            value,
            onChange: this.onChange
        };
        return (
            <div>
                <Header />
                <div className="sub-header-div">
                    <div className="container">
                        <h5 className="back-btn-header" type="button" onClick={this.onBack}>Back</h5>
                        <h3 className="comman-title"> transfer credit evaluations </h3>
                    </div>
                </div>
                <div className="container">
                    <div className="form-group label-header">
                        <label> {this.state.applicant.FirstName + " " + this.state.applicant.LastName} </label>
                    </div>
                    <button type="submit" className="btn btn-primary comman-site-btn add-institution"
                        disabled={(this.loggedInUserRoleType === 1 || this.loggedInUserRoleType === 2)} onClick={this.handleShowInstitute} >
                        <span className="glyphicon glyphicon-plus" aria-hidden="true"></span>  Add TCE
                    </button>
                </div>
                <div className="full-width full-width-comman">
                    <div className="container">
                        <div className="ag-theme-material"
                            style={{
                                height: '369px',
                                width: '100%'
                            }}
                        >
                            <AgGridReact
                                columnDefs={this.state.columnDefs}
                                rowData={this.state.rowData}
                                pagination={true}
                                paginationPageSize={this.state.paginationPageSize}
                                suppressRowClickSelection={true}
                                suppressCellSelection={true}
                                frameworkComponents={this.state.frameworkComponents}
                                context={this.state.context}
                                enableCellTextSelection={true}
                            >
                            </AgGridReact>
                        </div>
                    </div>
                </div>
                <Footer />

                <Modal className="modal fade comman-modal" id="add-new-applicant" show={this.state.showDlAddDegree} onHide={this.handleCloseInstitute}>
                    <Modal.Header className="modal-header" closeButton>
                        <Modal.Title>Add NEW TCE</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <form onSubmit={e => { e.preventDefault(); }}>
                            <div className="form-group">
                                <label>Degree Requirement</label>
                                {/* <input type="text" className="form-control amount" placeholder="GRAD / MSED / Child Ed 1-6 (2018/Fall)" /> */}
                                <Autosuggest
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                    getSuggestionValue={getSuggestionValue}
                                    shouldRenderSuggestions={shouldRenderSuggestions}
                                    renderSuggestion={renderSuggestion}
                                    inputProps={inputProps} />
                                <span className='error'>{errors.value}</span>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="btn-group">
                            <button type="button" variant="secondary" className="btn btn-primary comman-site-btn" onClick={this.handleCloseInstitute}>Cancel</button>
                            <button type="button" variant="primary" className="btn btn-primary comman-site-btn" onClick={this.onAddDegree}>ADD</button>
                        </div>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    };
}

export default TransferCredit

export class CPButtonRenderer extends Component {
    constructor(props) {
        super(props);
        this.removeTCEMapping = this.removeTCEMapping.bind(this);
        this.openTCEMapping = this.openTCEMapping.bind(this);
        this.approveTCEAdmission = this.approveTCEAdmission.bind(this);
        this.openTCEMappingView = this.openTCEMappingView.bind(this);
        this.sendToAcademicOfficer = this.sendToAcademicOfficer.bind(this);
        this.generateTCE = this.generateTCE.bind(this);
    }

    openTCEMapping() {
        this.props.context.componentParent.openTCEMapping(this.props.node);
    }

    openTCEMappingView() {
        this.props.context.componentParent.openTCEMappingView(this.props.node);
    }

    removeTCEMapping() {
        this.props.context.componentParent.removeTCEMapping(this.props.node);
    }

    approveTCEAdmission() {
        this.props.context.componentParent.approveTCEAdmission(this.props.node);
    }

    generateTCE() {
        this.props.context.componentParent.generateTCE(this.props.node);
    }

    sendToAcademicOfficer() {
        this.props.context.componentParent.sendToAcademicOfficer(this.props.node);
    }

    render() {

        return (
            <div>
                <button type="button" className="btn btn-primary comman-site-btn"
                    style={(this.props.node.data.AdmissionOfficerStatus === "Submitted" || this.props.node.data.AdmissionOfficerStatus === "StudentApproval" || this.props.node.data.AcademicOfficerStatus === "Submitted") ? { display: 'none' } : { display: '' }}
                    disabled={this.props.node.data.AdmissionOfficerStatus === "Submitted" || this.props.node.data.AdmissionOfficerStatus === "StudentApproval" || this.props.node.data.AcademicOfficerStatus === "Submitted"}
                    onClick={this.openTCEMapping} >
                    <img src={ImgEdit} alt="ImgEdit" />
                </button>
                <button type="button" className="btn btn-primary comman-site-btn"
                    style={(this.props.node.data.AdmissionOfficerStatus === "Submitted" || this.props.node.data.AdmissionOfficerStatus === "StudentApproval" || this.props.node.data.AcademicOfficerStatus === "Submitted") ? { display: '' } : { display: 'none' }}
                    // disabled={this.props.node.data.AdmissionOfficerStatus !== "Submitted"}
                    onClick={this.openTCEMappingView} >
                    <img src={ImgViewTCE} alt="ImgViewTCE" />
                </button>
                <button type="submit" className="btn btn-primary comman-site-btn"
                    onClick={this.sendToAcademicOfficer}
                    disabled={this.props.node.data.AdmissionOfficerStatus === "Submitted" || this.props.node.data.AdmissionOfficerStatus === "StudentApproval" || this.props.node.data.AcademicOfficerStatus === "Submitted"} >
                    To Evaluator
                    </button>
                <button type="submit" className="btn btn-primary comman-site-btn" onClick={this.generateTCE}>Send To Slate</button>
                <button type="submit" className="btn btn-primary comman-site-btn"
                    disabled={this.props.node.data.AdmissionOfficerStatus === "Submitted" || this.props.node.data.AcademicOfficerStatus === "Submitted"}
                    onClick={this.approveTCEAdmission}>
                    Student Accepted
                     </button>
                <button type="button" className="btn btn-primary comman-site-btn"
                    disabled={this.props.node.data.AdmissionOfficerStatus === "Submitted" }
                    // || this.props.node.data.AdmissionOfficerStatus === "StudentApproval"}
                    onClick={this.removeTCEMapping}>
                    <img src={ImgRemove} alt="ImgRemove" />
                </button>
            </div>
        );
    }
};