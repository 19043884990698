import React, { Component } from "react";
import HttpService from "../http/http-service";
import logo from "../assets/images/logo.png";
import Footer from "../general/footer";
import SwAlert from "sweetalert2";
import { runWithAdal } from "react-adal";
import { authContext, loginRequest, msalConfig } from "../general/adalConfig";
import ReactDOM from "react-dom";
import Routes from "../routes";
//import * as serviceWorker from '../serviceWorker';
import { MsalContext } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

const DO_NOT_LOGIN = false;
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};
//'success' | 'error' | 'warning' | 'info' | 'question'
const Toast = SwAlert.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

class LoginForm extends Component {
//   static contextType = MsalContext;
  constructor(props) {
    super(props);
    this.state = {
      formControls: {
        Username: {
          value: "",
        },
        Password: {
          value: "",
        },
      },
      loggedInUser: {},
      errors: {
        Username: "",
        Password: "",
      },
      userName: "",
      isAutheticated: false
    };
    this.publicClientApplication = new PublicClientApplication(msalConfig)
    this.loginUser = this.loginUser.bind(this);
  }

  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      formControls: {
        ...this.state.formControls,
        [name]: {
          ...this.state.formControls[name],
          value,
        },
      },
    });
    this.validationHandler(name, value);
  };

  async componentDidMount() {
    // if(!localStorage.getItem('user'))
    // {
    //     localStorage.setItem('user', 'admission');
    // }
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let userNameQ = params.get("u");
    let timeTicks = params.get("s");
    if (userNameQ && timeTicks) {
      this.validateUser(userNameQ, timeTicks);
    }
  }

  async validateUser(userNameQ, timeTicks) {
    const data = "user=" + userNameQ + "&tick=" + timeTicks;
    const result = await HttpService.get("Users/ValidateUser?" + data);
    if (result && result.ReturnStatus === true) {
      if (result.Data) {
        this.state.userName = result.Data;
        this.loginUser();
      }
    }
  }

  validationHandler(name, value) {
    let errors = this.state.errors;

    switch (name) {
      case "Username":
        errors.Username = value ? "" : "Enter Username.";
        break;
      case "Password":
        errors.Password = value ? "" : "Enter Password.";
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  }

  async loginUser(e) {
    //e.preventDefault();
    //this.state.userName = this.state.formControls.Username.value;
    //var password = this.state.formControls.Password.value;
    //this.validationHandler("Username", this.state.userName);
    //this.validationHandler("Password", password);
    //this.state.userName = localStorage.getItem('user'); {/* FOR NON ADFS */}
    if (validateForm(this.state.errors)) {
      let data = { Username: this.state.userName };
      const result = await HttpService.post("Users/GetLoginUser", data, "");
      if (result && result.ReturnStatus === true) {
        this.setState({ loggedInUser: result.Data });
        const date = new Date();
        var object = {
          value: "value",
          timestamp: date.setTime(date.getTime() + 30 * 60000),
        };
        localStorage.setItem("session", JSON.stringify(object));
        localStorage.setItem("loggedInUserId", result.Data.Id);
        localStorage.setItem("loggedInUserEmail", result.Data.Email);
        //lok9calStorage.setItem('loggedInUserName', result.Data.Username);
        //localStorage.setItem('loggedInUserFullName', result.Data.FirstName + " " + result.Data.LastName);
        localStorage.setItem("loggedInUserRoleType", result.Data.UserRoleType);
        this.navigateToDashboard(result);
      } else {
        Toast.fire({ title: result.Message, type: "error", timer: 10000 });
      }
    }
  }

  async getAdfsUser() {
    sessionStorage.clear();
    // runWithAdal(authContext, () => {
    //     ReactDOM.render(<Routes />, document.getElementById('root'));
    //     // If you want your app to work offline and load faster, you can change
    //     // unregister() to register() below. Note this comes with some pitfalls.
    //     // Learn more about service workers: https://bit.ly/CRA-PWA
    //     //serviceWorker.register();
    // }, DO_NOT_LOGIN);

    await this.publicClientApplication.loginPopup(loginRequest)
    this.setState({isAutheticated: true})

    const accounts = this.publicClientApplication.getAllAccounts();
    const  [{ username: username }] = accounts;
    this.setState({userName: username});

      await this.loginUser()
  }

  navigateToDashboard(response) {
    if (this.state.loggedInUser.UserRoleType === 0) {
      this.props.history.push("/dashboard"); //Admission
    } else if (this.state.loggedInUser.UserRoleType === 1) {
      this.props.history.push("/dashboard"); //Academics
    } else if (this.state.loggedInUser.UserRoleType === 2) {
      this.props.history.push("/dashboard"); //Registrar
    }
  }

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div className="full-width login">
          <div className="login-left">
            <div className="logo">
              <img src={logo} alt="logo" />
            </div>
          </div>
          <div className="login-right">
            <div className="login-form">
              <h2>Sign in here</h2>
              {/* FOR NON ADFS */}
              {/* <div className="form-group">
                                <input type="test" 
                                name="Username"
                                onChange={this.changeHandler}></input>
                                <button type="submit" className="btn btn-primary login-btn comman-btn" onClick={this.loginUser}>Sign In local</button>
                            </div> */}
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-primary login-btn comman-btn"
                  onClick={() => {this.getAdfsUser()}}>
                  Sign In
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default LoginForm;
