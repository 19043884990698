import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';

export const adalConfig = {
  instance: "https://fs.mcny.edu/adfs/ls/",
  tenant: 'common',
  clientId: 'E19BA200-23A3-4D08-914E-E786BF33426E',
  redirectUri: 'http://localhost:3000',
  extraQueryParameter: 'wtrealm=urn:TCETest&wa=wsignin1.0',
  endpoints: {
    api: 'E19BA200-23A3-4D08-914E-E786BF33426E'
  },
  cacheLocation: 'localStorage',
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);

export function authenticateToken() {
  if (authContext.getCachedUser()) {
    // If we have a cached login, use it
    return true
  }

  if (authContext.isCallback(window.location.hash)) {
    // This happens after the AD login screen,
    // handleWindowCallback will use the hash to
    // complete the login
    authContext.handleWindowCallback()
    return true
  }

  // Not logged in
  return false
}

export function azureApiRequest(method, resource, body) {
  var resourceUrl = adalConfig.endpoints.api + resource;

  // Use the client ID of your app for this call,
  // same as in the configuration earlier
  var bearerToken = authContext.getCachedToken('E19BA200-23A3-4D08-914E-E786BF33426E')

  var opts = {
    method: method,
    headers: {
      'Authorization': 'Bearer ' + bearerToken
    }
  }

  // I'm using JSON for my API, you can change this to your
  // heart's desire
  if (body) {
    opts.body = JSON.stringify(body)
    opts.headers['Content-Type'] = 'application/json'
  }

  return fetch(resourceUrl, opts)
    .then(response => {
      return response.json()
    }).catch(function (error) {
      console.log("Network problem: " + error)
      // Inspect the error further to see what is actually wrong
    }
    )
}

export const loginRequest = {
  scopes: ["User.Read"],
  // scopes: [ "api://77c7fa58-d613-4782-bcbe-325c311566e8/My.Scope" ]
  prompt: "select_account"
};

export const msalConfig = {
  auth: {
    clientId: "77c7fa58-d613-4782-bcbe-325c311566e8",
    authority: "https://login.microsoftonline.com/e23d448e-63c4-41c7-ac17-b2e4c6a1d477", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: "https://tce.mcny.edu/",
    postLogoutRedirectUri: "https://tce.mcny.edu/",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};