import React, { Component, useState } from 'react';
import HttpService from '../http/http-service';
import Header from "../general/header";
import Footer from "../general/footer";
import { AgGridReact } from 'ag-grid-react';
import { Modal } from 'react-bootstrap';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import ImgEdit from '../assets/images/icon_edit.png';
import ImgRemove from '../assets/images/delete.png';
import Autosuggest from 'react-autosuggest';
import SwAlert from 'sweetalert2'

const Toast = SwAlert.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
})

var institutes = []
var selectedInstitute = null;
var loggedInUserRoleType = localStorage.getItem("loggedInUserRoleType") ? Number(localStorage.getItem("loggedInUserRoleType")) : -1;
var loggedInUserId = localStorage.getItem("loggedInUserId") ? localStorage.getItem("loggedInUserId") : '00000000-0000-0000-0000-000000000000';

function renderSuggestion(suggestion) {
    return (
        <div>
            <span>{suggestion.OrgName}</span>
            <span className="city-name">{suggestion.City}</span>
        </div>
    );
}

function shouldRenderSuggestions() {
    return true;
}

function getSuggestionValue(suggestion) {
    selectedInstitute = suggestion;
    return suggestion.OrgName;
}

function getSuggestions(value) {
    return institutes.filter(institute => String(institute.OrgName).toLowerCase().includes(value.toLowerCase()));
}

function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}

class CoursePortfolioView extends Component {

    ApplicantID = null;

    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [
                { field: "CourseCode", headerName: "COURSE CODE", sortable: true, width: 220 },
                { field: "CourseTitle", headerName: "TITLE", sortable: true, width: 500 },
                { field: "Grade", headerName: "GRADE", sortable: true, width: 200 },
                { field: "Credit", headerName: "CREDITS", sortable: true, width: 220 }
            ],
            rowData: [],
            context: { componentParent: this },
            applicantInstituteData: [],
            gridOptions: {
                defaultColDef: {
                    editable: false
                }
            },
            paginationPageSize: 5,
            showDlAddInstitute: false,
            showDlAddCourse: false,
            applicant: [],
            elements: [],
        };
    }

    onBack = () => {
        this.props.history.push('/dashboard')
    }

    componentDidMount() {
        this.ApplicantID = this.props.match.params.ApplicantID;
        this.checkSession();
        this.getCoursePortfolioList();
        this.getApplicant(this.ApplicantID);
        this.getApplicantOrganization();
    }

    checkSession() {
        var object = JSON.parse(localStorage.getItem("session"));
        if (object) {
            var dateString = new Date(object.timestamp);
            var now = new Date();

            console.log('dateString ' + dateString);
            console.log('now ' + now);
            if (now > dateString) {
                this.props.history.push('/');
                Toast.fire({ title: 'Session expired!', type: 'info' });
            }
        }
    }

    async getCoursePortfolioList() {
        let data = "id=" + this.ApplicantID;
        const result = await HttpService.get('CoursePortfolios/GetCoursePortfolio?' + data);
        if (result && result.ReturnStatus === true) {
            this.setState({ rowData: result.Data });
            setTimeout(() => {
                this.createCoursePortfolio();
            }, 100);
        } else {
            alert("Course portfolio not found.");
        }
    }

    async getApplicant(ApplicantID) {
        let data = "id=" + ApplicantID;
        const result = await HttpService.get('Applicants/GetApplicantsPeopleById?' + data);
        if (result && result.ReturnStatus === true) {
            this.setState({ applicant: result.Data });
        } else {
            alert("Applicants not found.");
        }
    }

    async getApplicantOrganization() {
        let data = "id=" + this.ApplicantID;
        const result = await HttpService.get('ApplicantOrganizations/GetApplicantOrganization?' + data);
        if (result && result.ReturnStatus === true) {
            this.setState({ applicantInstituteData: result.Data });
            setTimeout(() => {
                this.createCoursePortfolio();
            }, 100);
        } else {
            alert("Applicant's organization list not found.");
        }
    }

    createCoursePortfolio() {
        let elementsTemp = [];
        for (var i = 0; i < this.state.applicantInstituteData.length; i++) {
            let rows = this.state.rowData.filter(x => x.ApplicantOrganizationId === this.state.applicantInstituteData[i]["Id"]);
            let creditTotal = 0;
            rows.forEach(element => {
                element.Grade = element.Grade + (element.Suffix == "none" ? "" : element.Suffix);
                creditTotal += element.Credit;
            });
            let gridHeight = rows.length ? (rows.length * 50) + 130 : 180;
            let totalRows = [{ CourseCode: 'Total', CourseTitle: '', Grade: '', Credit: creditTotal }]
            // push the component to elements!
            elementsTemp.push(
                <div className="corse-table" key={i}>
                    <CoursePortfolioGrid
                        columnDefs={this.state.columnDefs}
                        rowData={rows}
                        totalData={totalRows}
                        instituteName={this.state.applicantInstituteData[i]["OrganizationName"] + "(" + this.state.applicantInstituteData[i]["OrganizationCodeId"] + ")"}
                        instituteId={this.state.applicantInstituteData[i]["Id"]}
                        frameworkComponents={this.state.frameworkComponents}
                        context={this.state.context}
                        gridHeight={gridHeight}
                    />
                </div>
            );
        }

        if (elementsTemp.length == 0) {
            elementsTemp.push(
                <div key="no-record" className="no-record">No Records</div>
            );
        }

        this.setState({ elements: elementsTemp })
    }

    createInstitutesItems() {
        var items = [];
        if (this.state.applicantInstituteData.length > 0) {
            for (var i = 0; i < this.state.applicantInstituteData.length; i++) {
                var obj = this.state.applicantInstituteData[i];
                items.push(<option key={i} value={obj.Id}>{obj.OrganizationName}</option>);
            }
        }
        return items;
    }

    render() {

        const { value, suggestions, errors } = this.state;
        return (
            <div>
                <Header />
                <div className="sub-header-div">
                    <div className="container">
                        <h5 className="back-btn-header" type="button" onClick={this.onBack}>Back</h5>
                        <h3 className="comman-title">view transfer course portfolio</h3>
                    </div>
                </div>
                <div className="container">
                    <div className="form-group label-header">
                        <label> {this.state.applicant.FirstName + " " + this.state.applicant.LastName} </label>
                    </div>
                </div>
                <div className="full-width full-width-comman">
                    <div className="container">
                        {this.state.elements}
                    </div>
                </div>
                <Footer />
            </div>
        );
    };
}

export default CoursePortfolioView

class CoursePortfolioGrid extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div>
                <div className="ag-theme-material"
                    style={{
                        height: this.props.gridHeight + 'px',
                        width: '100%'
                    }}
                >
                    <div className="table-header">
                        <label>{this.props.instituteName}</label>
                        <div className="header-action">

                        </div>
                    </div>
                    <div style={{ height: (this.props.rowData.length > 0 ? (this.props.rowData.length + 1) * 46 : (2.5 * 46)), width: '100%' }}>
                        <AgGridReact
                            headerHeight="46"
                            columnDefs={this.props.columnDefs}
                            rowData={this.props.rowData}
                            suppressRowClickSelection={true}
                            suppressCellSelection={true}
                            frameworkComponents={this.props.frameworkComponents}
                            context={this.props.context}
                            enableCellTextSelection={true}
                            rowHeight="46"
                        >
                        </AgGridReact>
                    </div>
                    <div style={this.props.rowData.length > 0 ? { height: '47px', width: '100%', display: '' } : { display: 'none' }} >
                        <AgGridReact
                            columnDefs={this.props.columnDefs}
                            rowData={this.props.totalData}
                            suppressRowClickSelection={true}
                            suppressCellSelection={true}
                            context={this.props.context}
                            enableCellTextSelection={true}
                            headerHeight="0"
                            rowHeight="46"
                            frameworkComponents={this.props.frameworkComponents}
                            rowStyle={{ fontWeight: 'bold' }}
                        >
                        </AgGridReact>
                    </div>

                </div >
            </div >
        );
    }
}