import React, { Component } from 'react';
class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="container">
                    <span className="copy-right"> © 2019 MCNY.</span>
                    <span className="pull-right">ALL RIGHTS reserved</span>
                </div>
            </footer>
        );
    }
}

export default Footer;